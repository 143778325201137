import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Typography,
  Breadcrumb,
  Tabs,
  Spin,
  DatePicker,
  Tooltip,
  Radio,
  Card,
} from 'antd';
import _ from 'lodash';
import '../CustomTable/CustomTable.css';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getQueue, getQueueById } from '../../Actions/QueueAction';
import CustomTable from '../CustomTable/CustomTable';
import moment from 'moment-timezone';
import { useStopwatch } from 'react-timer-hook';
import {
  BarbecueGrill,
  DisabledChair,
  KidSeatQueue,
} from '../../Utils/svgImage';
import QueueDetails from './QueueDetails';
import {
  getCaptainTables,
  getCaptainTablesById,
  getTables,
  getTablesById,
} from '../../Actions/TableAction';
import { getLayout } from '../../Actions/LayoutAction';
import chair from '../../assests/chair.svg';
import members from '../../assests/members.svg';
import SearchComponent from '../../Utils/SearchComponent';

const Queue = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { orgId } = useParams();
  const {
    allLayout,
    allCaptainTables,
    restaurant,
    allQueue,
    isQueueLoading,
    isTablesloading,
  } = useSelector((state) => {
    const { allQueue, isQueueLoading } = state.queue;
    const { restaurant } = state.restaurant;
    const { allCaptainTables, isTablesloading } = state.tables;
    const { allLayout } = state.layout;
    return {
      restaurant,
      allLayout,
      allCaptainTables,
      allQueue,
      isQueueLoading,
      isTablesloading,
    };
  });

  const [tabKey, setTabKey] = useState('1');
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [tabDates, setTabDates] = useState({
    1: moment(),
    2: moment(),
    4: moment(),
    5: moment(),
  });
  const [selectedLayoutId, setSelectedLayoutId] = useState(null);

  useEffect(() => {
    const defaultLayoutId = allLayout[parseInt('0')]?.id;
    setSelectedLayoutId(defaultLayoutId);
  }, [allLayout]);

  const onChangeDate = (date) => {
    setTabDates((prev) => ({
      ...prev,
      [tabKey]: date || moment(),
    }));
  };

  const organizationTimezone =
    restaurant?.organizations_detail?.organization_time_zone;
  const currentDate = moment().format('DD/MM/YYYY');
  const formattedDate = tabDates[tabKey]?.format('DD/MM/YYYY') || currentDate;

  useEffect(() => {
    if (tabKey === '1') {
      dispatch(
        getQueue(`?queue_type=waitlist&reservation_date=${formattedDate}`)
      );
    } else if (tabKey === '2') {
      dispatch(
        getQueue(`?queue_type=reservation&reservation_date=${formattedDate}`)
      );
    } else if (tabKey === '4') {
      dispatch(
        getQueue(`?queue_status=completed&reservation_date=${formattedDate}`)
      );
    } else if (tabKey === '5') {
      dispatch(
        getQueue(`?queue_status=cancelled&reservation_date=${formattedDate}`)
      );
    } else if (tabKey === '3') {
      dispatch(getCaptainTables());
      dispatch(getLayout(`api/organization_layouts`));
    }
  }, [tabKey, tabDates, dispatch]);

  const handleCloseRestaurant = () => {
    navigate('/restaurants');
    dispatch({
      type: 'GET_SELECTED_RESTAURANT_ID',
      payload: '',
    });
  };

  const onChangeTab = (key) => {
    setTabKey(key);
  };

  const handleOpen = (id) => {
    setDrawerVisible(true);
    if (tabKey === '3') {
      dispatch(getCaptainTablesById(id));
    } else {
      dispatch(getQueueById(id));
    }
  };

  const waitlistColumn = [
    {
      title: 'Queue no',
      dataIndex: 'queue_number',
      width: '10%',
      render: (Id, { id, queue_number }) => {
        return (
          <Row>
            <Col>
              <Typography>{_.split(queue_number, '.')[1]}</Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Customer',
      dataIndex: 'user',
      width: '15%',
      render: (user) => {
        return (
          <Row>
            <Col style={{ cursor: 'pointer' }}>
              <Tooltip
                title={`View Customer`}
                onClick={() =>
                  navigate(
                    `/restaurants/${orgId}/customers/${user?.id}/customerdetails`
                  )
                }
              >
                <Typography>
                  {user.country_code}
                  {user.phone}
                </Typography>
                <Typography>
                  {user.first_name} {user.last_name}
                </Typography>
              </Tooltip>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Size',
      dataIndex: 'total_guests',
      width: '8%',
      render: (total_guests) => {
        return (
          <Row>
            <Col>
              <Typography>{total_guests}</Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Booking Time',
      dataIndex: 'created_at',
      width: '10%',
      render: (created_at) => {
        const booking_time = moment(created_at)
          .tz(organizationTimezone)
          .format('hh:mm A');
        return (
          <Row>
            <Col>
              <Typography>{booking_time}</Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Estimated Time',
      dataIndex: 'reservation_time',
      width: '15%',
      render: (id, { reservation_time, created_at }) => {
        const booking_time = moment(created_at)
          .tz(organizationTimezone)
          .format('hh:mm A');
        const diff = moment(reservation_time, 'HH:mm').diff(
          moment(booking_time, 'hh:mm A'),
          'minutes'
        );
        return (
          <Row>
            <Col>
              <Typography>{reservation_time}</Typography>
              <Typography style={{ opacity: '0.8' }}>
                ({`${diff} mins`})
              </Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Wait Time',
      dataIndex: 'created_at',
      width: '15%',
      render: (id, { reservation_time, created_at }) => {
        const booking_time = moment(created_at)
          .tz(organizationTimezone)
          .format('hh:mm A');
        const diff = moment(reservation_time, 'HH:mm').diff(
          moment(booking_time, 'hh:mm A'),
          'minutes'
        );
        return (
          <Row>
            <Col>
              <Typography>
                <QueueWaitTime
                  data={moment.utc(created_at).tz(organizationTimezone)}
                  estimateWaitTimeinMin={diff}
                />
              </Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Layout Preference',
      dataIndex: 'organization_layout',
      width: '15%',
      render: (organization_layout) => {
        return (
          <Row>
            <Col>
              <Typography>{organization_layout?.layout_name}</Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Extra Details',
      dataIndex: 'kids_seat',
      width: '15%',
      render: (id, { kids_seat, barbeque_seat, disabled_seat }) => {
        return (
          <Row>
            <Col>
              <Typography>
                <span style={{ marginRight: 10 }}>
                  {kids_seat ? (
                    <KidSeatQueue color='#219653' />
                  ) : (
                    <KidSeatQueue color='#EB5757' />
                  )}
                </span>
                <span style={{ marginRight: 10 }}>
                  {barbeque_seat ? (
                    <BarbecueGrill color='#219653' />
                  ) : (
                    <BarbecueGrill color='#EB5757' />
                  )}
                </span>
                <span>
                  {disabled_seat ? (
                    <DisabledChair color='#219653' />
                  ) : (
                    <DisabledChair color='#EB5757' />
                  )}
                </span>
              </Typography>
            </Col>
          </Row>
        );
      },
    },
  ];

  const reservationColumn = [
    {
      title: 'Queue no',
      dataIndex: 'queue_number',
      width: '10%',
      render: (queue_number) => {
        return (
          <Row>
            <Col>
              <Typography>{_.split(queue_number, '.')[1]}</Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Customer',
      dataIndex: 'user',
      width: '15%',
      render: (user) => {
        return (
          <Row>
            <Col style={{ cursor: 'pointer' }}>
              <Tooltip
                title={`View Customer`}
                onClick={() =>
                  navigate(
                    `/restaurants/${orgId}/customers/${user?.id}/customerdetails`
                  )
                }
              >
                <Typography>
                  {user.country_code}
                  {user.phone}
                </Typography>
                <Typography>
                  {user.first_name} {user.last_name}
                </Typography>
              </Tooltip>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Size',
      dataIndex: 'total_guests',
      width: '8%',
      render: (total_guests) => {
        return (
          <Row>
            <Col>
              <Typography>{total_guests}</Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Booking Time',
      dataIndex: 'created_at',
      width: '10%',
      render: (created_at) => {
        const booking_time = moment(created_at)
          .tz(organizationTimezone)
          .format('hh:mm A');
        const booking_date = moment(created_at).format('DD/MM/YYYY');
        return (
          <Row>
            <Col>
              <Typography>{booking_time}</Typography>
              <Typography>{booking_date}</Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Reservation Time',
      dataIndex: 'reservation_time',
      width: '15%',
      render: (id, { reservation_time, reservation_date }) => {
        return (
          <Row>
            <Col>
              <Typography>{reservation_time}</Typography>
              <Typography>{reservation_date}</Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Layout Preference',
      dataIndex: 'organization_layout',
      width: '15%',
      render: (organization_layout) => {
        return (
          <Row>
            <Col>
              <Typography>{organization_layout?.layout_name}</Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Extra Details',
      dataIndex: 'kids_seat',
      width: '10%',
      render: (id, { kids_seat, barbeque_seat, disabled_seat }) => {
        return (
          <Row>
            <Col>
              <Typography>
                <span style={{ marginRight: 10 }}>
                  {kids_seat ? (
                    <KidSeatQueue color='#219653' />
                  ) : (
                    <KidSeatQueue color='#EB5757' />
                  )}
                </span>
                <span style={{ marginRight: 10 }}>
                  {barbeque_seat ? (
                    <BarbecueGrill color='#219653' />
                  ) : (
                    <BarbecueGrill color='#EB5757' />
                  )}
                </span>
                <span>
                  {disabled_seat ? (
                    <DisabledChair color='#219653' />
                  ) : (
                    <DisabledChair color='#EB5757' />
                  )}
                </span>
              </Typography>
            </Col>
          </Row>
        );
      },
    },
  ];

  const completedColumn = [
    {
      title: 'Booking no',
      dataIndex: 'queue_number',
      width: '10%',
      render: (Id, { id, queue_number }) => {
        return (
          <Row>
            <Col>
              <Tooltip title={`View ${queue_number} Details`}>
                <Typography
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleOpen(id)}
                >
                  {_.split(queue_number, '.')[1]}
                </Typography>
              </Tooltip>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Customer',
      dataIndex: 'user',
      width: '15%',
      render: (user) => {
        return (
          <Row>
            <Col style={{ cursor: 'pointer' }}>
              <Tooltip
                title={`View Customer`}
                onClick={() =>
                  navigate(
                    `/restaurants/${orgId}/customers/${user?.id}/customerdetails`
                  )
                }
              >
                <Typography>
                  {user.country_code}
                  {user.phone}
                </Typography>
                <Typography>
                  {user.first_name} {user.last_name}
                </Typography>
              </Tooltip>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Booking Type',
      dataIndex: 'queue_type',
      width: '10%',
      render: (queue_type) => {
        return (
          <Row>
            <Col>
              <Typography>{_.capitalize(queue_type)}</Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Created Time',
      dataIndex: 'created_at',
      width: '15%',
      render: (created_at) => {
        const booking_time = moment(created_at)
          .tz(organizationTimezone)
          .format('HH:mm');
        const booking_date = moment(created_at).format('DD/MM/YYYY');
        return (
          <Row>
            <Col>
              <Typography>{booking_time}</Typography>
              <Typography>{booking_date}</Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Total serve time',
      dataIndex: 'serve_time',
      width: '15%',
      render: (serve_time) => {
        return (
          <Row>
            <Col>
              <Typography>Serve time</Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Completed time	',
      dataIndex: 'queue_activities',
      width: '15%',
      render: (queue_activities) => {
        return (
          <Row>
            <Col>
              {queue_activities?.map((item, index) => {
                const completedTime = moment(item?.created_at)
                  .tz(organizationTimezone)
                  .format('hh:mm A');

                return (
                  <Typography key={index}>
                    {tabKey === '4' && item?.activity_type === 'completed'
                      ? completedTime
                      : ''}
                    {tabKey === '5' &&
                    item?.activity_type ===
                      ('cancelled_by_admin' || 'cancelled_by_user')
                      ? completedTime
                      : ''}
                  </Typography>
                );
              })}
            </Col>
          </Row>
        );
      },
    },
  ];

  const items = allLayout?.map((item, index) => ({
    key: index,
    label: item.layout_name,
  }));

  const handleTabChange = (activeKey) => {
    const selectedLayoutId = allLayout[parseInt(activeKey)]?.id;
    setSelectedLayoutId(selectedLayoutId);
  };

  const filteredTables = allCaptainTables?.organization_tables?.filter(
    (table) => table?.organization_layout?.id === selectedLayoutId
  );

  const additionalParams = {
    ...(tabKey === '1' ? { queue_type: 'waitlist' } : {}),
    ...(tabKey === '2' ? { queue_type: 'reservation' } : {}),
    ...(tabKey === '4' ? { queue_status: 'completed' } : {}),
    ...(tabKey === '5' ? { queue_status: 'cancelled' } : {}),
    reservation_date: formattedDate,
  };

  return (
    <Spin spinning={isQueueLoading || isTablesloading}>
      <Col span={24}>
        <Row gutter={[24, 24]}>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <Row gutter={[24, 24]}>
              <Col xl={23} lg={23} md={23} sm={23} xs={23}>
                <Breadcrumb separator='>'>
                  <Breadcrumb.Item
                    onClick={() => handleCloseRestaurant()}
                    className='breadcrumRestaurant'
                  >
                    Restaurants
                  </Breadcrumb.Item>
                  <Breadcrumb.Item
                    onClick={() => navigate(-1)}
                    className='breadcrumRestaurant'
                  >
                    {restaurant?.name}
                  </Breadcrumb.Item>
                  <Breadcrumb.Item className='breadcrumUsers'>
                    Queue
                  </Breadcrumb.Item>
                </Breadcrumb>
              </Col>
              <Col span={18}>
                <Typography className='heading'>Queue</Typography>
              </Col>
            </Row>
          </Col>

          <Col span={12}>
            <Tabs
              defaultActiveKey='1'
              className='branding-tab'
              onChange={onChangeTab}
              items={[
                {
                  label: `Waitlist`,
                  key: '1',
                },
                {
                  label: `Reservation`,
                  key: '2',
                },
                {
                  label: `Table View`,
                  key: '3',
                },
                {
                  label: `Completed`,
                  key: '4',
                },
                {
                  label: `Cancelled`,
                  key: '5',
                },
              ]}
            />
          </Col>
          {tabKey !== '1' && tabKey !== '3' && (
            <Col
              span={12}
              style={{
                display: 'flex',
                justifyContent: 'end',
                alignItems: 'center',
              }}
            >
              <span style={{ marginRight: '10px' }}>
                <SearchComponent
                  search={[{ key: 'queue_number', label: 'Queue Number' }]}
                  placeholder='Search by Queue number'
                  searchMethod={getQueue}
                  additionalParams={additionalParams}
                />
              </span>
              <DatePicker
                value={tabDates[tabKey]}
                onChange={onChangeDate}
                format='DD/MM/YYYY'
                size='large'
                style={{ borderRadius: '5px', border: '1px solid #ccc' }}
              />
            </Col>
          )}
          <Col span={24}>
            <Row>
              <Col span={24}>
                <Row gutter={[16, 16]} className='button'>
                  <Col span={24}>
                    {tabKey === '1' ? (
                      <Row>
                        <Col span={24}>
                          <CustomTable
                            columns={waitlistColumn}
                            data={allQueue}
                          />
                        </Col>
                      </Row>
                    ) : tabKey === '2' ? (
                      <Row>
                        <Col span={24}>
                          <CustomTable
                            columns={reservationColumn}
                            data={allQueue}
                          />
                        </Col>
                      </Row>
                    ) : tabKey === '3' ? (
                      <Row>
                        <Col span={24}>
                          <Tabs
                            defaultActiveKey='1'
                            className='custom-tabs'
                            style={{
                              border: '1.2px solid #ccc',
                              width: '100%',
                              margin: '0px',
                            }}
                            onChange={handleTabChange}
                            items={items}
                          />
                        </Col>
                        <Col
                          span={24}
                          style={{ border: '1px solid #ccc', height: '400px' }}
                        >
                          {filteredTables?.length > 0 &&
                            filteredTables.map((item, index) => (
                              <TableCard
                                key={index}
                                item={item}
                                handleOpen={handleOpen}
                                chair={chair}
                                members={members}
                              />
                            ))}

                          <QueueDetails
                            setDrawerVisible={setDrawerVisible}
                            drawerVisible={drawerVisible}
                            organizationTimezone={organizationTimezone}
                            tabKey={tabKey}
                          ></QueueDetails>
                        </Col>
                      </Row>
                    ) : tabKey === '4' ? (
                      <Row>
                        <Col span={24}>
                          <CustomTable
                            columns={completedColumn}
                            data={allQueue}
                          />
                          <QueueDetails
                            setDrawerVisible={setDrawerVisible}
                            drawerVisible={drawerVisible}
                            organizationTimezone={organizationTimezone}
                          ></QueueDetails>
                        </Col>
                      </Row>
                    ) : tabKey === '5' ? (
                      <Row>
                        <Col span={24}>
                          <CustomTable
                            columns={completedColumn}
                            data={allQueue}
                          />
                          <QueueDetails
                            setDrawerVisible={setDrawerVisible}
                            drawerVisible={drawerVisible}
                            organizationTimezone={organizationTimezone}
                          ></QueueDetails>
                        </Col>
                      </Row>
                    ) : null}
                  </Col>
                </Row>
                {/* <Col span={18}>
                <Row
                  gutter={[16, 16]}
                  className='button'
                  align='middle'
                  justify='center'
                >
                  <Col span={24}></Col>
                  <Col xl={8} lg={8} md={8} sm={12} xs={12}>
                    <Button
                      className={
                        isFieldChanged ? 'cancelButton' : 'classButton'
                      }
                      disabled={!isFieldChanged}
                      onClick={handleCancel}
                      size='large'
                    >
                      Cancel
                    </Button>
                  </Col>
                  <Col xl={8} lg={8} md={8} sm={12} xs={12}>
                    <Button
                      size='large'
                      className={
                        isFieldChanged ? 'detailsButton' : 'classButton'
                      }
                      disabled={!isFieldChanged}
                      htmlType='submit'
                    >
                      Update
                    </Button>
                  </Col>
                </Row>
              </Col> */}
                {/* </form> */}
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Spin>
  );
};

export const QueueWaitTime = (props) => {
  const { data, estimateWaitTimeinMin } = props;

  const { seconds } = useStopwatch({ autoStart: true });

  const [waitTime, setWaitTime] = useState(0);

  const queueTime = moment().diff(data, 'minutes');

  useEffect(() => {
    setWaitTime(queueTime);
  }, [seconds]);

  return (
    <Typography
      style={{
        color: waitTime > estimateWaitTimeinMin ? '#FC8019' : '#219653',
      }}
    >
      {' '}
      {`${waitTime} mins`}
    </Typography>
  );
};

const TableCard = ({ item, handleOpen, chair, members }) => {
  const orderHour = moment().diff(
    moment(item?.orders?.[0]?.order?.order_created),
    'hours'
  );
  const orderTime = moment().diff(
    moment(item?.orders?.[0]?.order?.order_created),
    'minutes'
  );

  const orderSeconds =
    moment().diff(moment(item?.orders?.[0]?.order?.order_created), 'seconds') %
    60;

  const [orderTimerInMinute, setOrderTimerInMinute] = useState(orderTime);

  useEffect(() => {
    setOrderTimerInMinute(orderTime > 60 ? orderTime % 60 : orderTime);
  }, [orderTime]);

  return (
    <div className='table-card' onClick={() => handleOpen(item?.id)}>
      <div className='custom-card'>
        <div
          className='card-header'
          style={{
            backgroundColor:
              item?.orders?.length > 0
                ? orderHour > 1
                  ? '#F19C51'
                  : '#006491'
                : item?.is_block
                ? '#EB5757'
                : '#219653',
          }}
        >
          <div style={{ marginRight: 10 }}>
            <img src={chair} style={{ marginRight: 4 }} alt='Chair Icon' />
            <span>{item?.seating_capacity}</span>
          </div>
          {item?.orders?.length > 0 && (
            <div>
              <img
                src={members}
                style={{ marginRight: 4 }}
                alt='Members Icon'
              />
              <span>{item?.orders?.[0]?.order?.members_on_table}</span>
            </div>
          )}
        </div>
        <div className='card-content'>
          <Typography style={{ color: '#fbfbfb' }}>
            {item?.table_number}
          </Typography>
          <Typography style={{ color: '#fbfbfb' }}>
            {item?.orders?.length > 0 &&
              (orderHour > 1
                ? '> 2hours'
                : `${orderHour}:${
                    orderTimerInMinute < 10 ? '0' : ''
                  }${orderTimerInMinute}:${
                    orderSeconds < 10 ? '0' : ''
                  }${orderSeconds}`)}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default Queue;
