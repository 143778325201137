import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Button,
  Pagination,
  Typography,
  message,
  Spin,
  Tooltip,
  Breadcrumb,
  Popconfirm,
} from 'antd';
import _ from 'lodash';
import '../CustomTable/CustomTable.css';
import { useDispatch, useSelector } from 'react-redux';
import CustomTable from '../CustomTable/CustomTable';
import {
  DeleteColumnAction,
  EditColumnAction,
} from '../CustomTable/CustomTableColumnAction';
import {
  getCategory,
  deleteCategory,
  updateCategory,
  getCategoryById,
  reOrderCategory,
  deleteMultiCategory,
} from '../../Actions/CategoryAction';
import CategoryModalForm from './CategoryModalForm';
import { useNavigate, useParams } from 'react-router-dom';
import plus from '../../assests/plus.svg';
import filtericon from '../../assests/filtericon.svg';
import CategoryDetails from './CategoryDetails';
import publish from '../../assests/publish.svg';
import unpublish from '../../assests/unpublish.svg';
import dragvertical from '../../assests/dragvertical.svg';
import { SortableHandle } from 'react-sortable-hoc';

const Category = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editId, setEditId] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sortedData, setSortedData] = useState([]);
  const [sortOrder, setSortOrder] = useState('asc');
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [categoryDetailId, setcategoryDetailId] = useState('');
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  let UnpublishData = {};
  let PublishData = {};
  const {
    isCategoryloading,
    allCategory,
    restaurant,
    setRestaurantId,
    Category,
  } = useSelector((state) => {
    const { isCategoryloading, allCategory, Category } = state.category;
    const { restaurant, setRestaurantId } = state.restaurant;
    return {
      restaurant,
      isCategoryloading,
      allCategory,
      Category,
      setRestaurantId,
    };
  });

  const { length } = allCategory || {};
  useEffect(() => {
    dispatch(getCategory(`api/categories?page=${currentPage}`));
  }, [dispatch, currentPage]);

  const successDelete = () => {
    setTimeout(() => {
      message.success(`Category Delete Successfully`);
    }, 1000);
    dispatch(getCategory(`api/categories?page=${currentPage}`));
  };
  const { orgId } = useParams();

  const handleOpen = (Id) => {
    setDrawerVisible(true);
    setcategoryDetailId(Id);
    navigate(`/restaurants/${orgId}/category/${Id}`);
    localStorage.setItem('organization_id', orgId);
    dispatch(getCategoryById(Id));
    dispatch({
      type: 'GET_SELECTED_CATEGORY_ID',
      payload: Id,
    });
  };
  const handleEdit = (id) => {
    setEditId(id);
    setIsModalVisible(true);
  };
  const handleCreate = (id) => {
    setIsModalVisible(id);
    setEditId(false);
  };
  const handlePublishMethod = (EditId) => {
    PublishData.id = EditId;
    PublishData.published = false;
    dispatch(
      updateCategory(PublishData, successUpdatePublishMethod, faliureDelete)
    );
  };
  const handleUnpublishMethod = (EditId) => {
    UnpublishData.id = EditId;
    UnpublishData.published = true;
    dispatch(
      updateCategory(UnpublishData, successUpdateUnpublishMethod, faliureDelete)
    );
  };

  const successUpdateUnpublishMethod = () => {
    setTimeout(() => {
      message.success('Your Category Published Successfully');
    }, 1000);
    dispatch(getCategory(`api/categories?page=${currentPage}`));
  };

  const successUpdatePublishMethod = () => {
    setTimeout(() => {
      message.success('Your Category Unpublished Successfully');
    }, 1000);
    dispatch(getCategory(`api/categories`));
  };
  const faliureDelete = () => {
    setTimeout(() => {
      message.error('Something went wrong.');
    }, 1000);
  };

  // const onCategoryDragEnd = (data) => {
  //   const selectedCategory = _.filter(allCategory, (val, i) => {
  //     return i === data.id;
  //   });
  //   data.position = selectedCategory[0]?.position;
  //   dispatch(reOrderCategory(data, UpdateCallBack, faliureUpdate));

  // };

  const DragHandle = SortableHandle(() => (
    <img src={dragvertical} style={{ cursor: 'pointer' }} />
  ));

  const handleCloseRestaurant = () => {
    navigate('/restaurants');
    dispatch(getCategoryById(''));
    dispatch({
      type: 'GET_SELECTED_RESTAURANT_ID',
      payload: '',
    });
  };
  const handleCloseRestaurantDrawer = () => {
    navigate(`/restaurants/${orgId}`);
  };
  const rowSelection = {
    selectedRowKeys,
    columnWidth: '3%',
    onChange: (selectedKeys) => {
      setSelectedRowKeys(selectedKeys);
    },
  };
  const deleteRows = () => {
    const selectedIds = allCategory
      .filter((item) => selectedRowKeys.includes(item.position))
      .map((item) => item.id);
    let newData = { category_ids: selectedIds };
    dispatch(deleteMultiCategory(newData, successMultiDelete));
  };

  const successMultiDelete = (res) => {
    setTimeout(() => {
      message.success(res?.message);
    }, 1000);
    dispatch(getCategory(`api/categories`));
  };

  const Columns = [
    {
      // className: 'drag-visible',
      width: '2%',
      render: () => <DragHandle />,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      className: 'drag-visible-name',
      width: '10%',
      render: (Id, { id, name }) => {
        return (
          <Row>
            <Col>
              <Tooltip title={`View ${name} Details`}>
                <Typography
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleOpen(id)}
                >
                  {name}
                </Typography>
              </Tooltip>
            </Col>
          </Row>
        );
      },
    },

    {
      title: 'Position',
      dataIndex: 'position',
      className: 'drag-visible-position',
      width: '6%',
      render: (position) => {
        return (
          <Row>
            <Col>
              <Typography className='UserDetailIndustryTextData'>
                {position}
              </Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Total Item',
      dataIndex: 'total_item',
      // className: 'drag-visible-total-item',
      width: '6%',
      render: (total_item) => {
        return (
          <Row>
            <Col>
              <Typography className='UserDetailIndustryTextData'>
                {total_item}
              </Typography>
            </Col>
          </Row>
        );
      },
    },

    {
      title: 'Action',
      dataIndex: 'id',
      // className: 'drag-visible-action',
      width: '6%',
      render: (id, { published }) => {
        return (
          <Row justify='space-around'>
            <Col>
              {published ? (
                <Tooltip title='Click for Unpublish'>
                  <img
                    src={publish}
                    style={{
                      cursor: 'pointer',
                      position: 'relative',
                      top: '15%',
                      padding: '0px 5px 0px 5px',
                    }}
                    onClick={() => handlePublishMethod(id)}
                  />
                </Tooltip>
              ) : (
                <Tooltip title='Click for Publish'>
                  <img
                    src={unpublish}
                    style={{
                      cursor: 'pointer',
                      position: 'relative',
                      top: '15%',
                      padding: '0px 5px 0px 5px',
                    }}
                    onClick={() => handleUnpublishMethod(id)}
                  />
                </Tooltip>
              )}
            </Col>
            <Col>
              <EditColumnAction id={id} customMethod={handleEdit} />
            </Col>
            <Col>
              <DeleteColumnAction
                id={id}
                actionArray={[deleteCategory]}
                customMethod={successDelete}
              />
            </Col>
          </Row>
        );
      },
    },
  ];

  return (
    <Spin spinning={isCategoryloading}>
      <Row gutter={[24, 24]}>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          {isModalVisible ? (
            <CategoryModalForm
              isModalVisible={isModalVisible}
              setIsModalVisible={setIsModalVisible}
              editId={editId}
              setEditId={setEditId}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              restaurant={restaurant}
            ></CategoryModalForm>
          ) : (
            <Row gutter={[24, 24]}>
              <Col xl={23} lg={23} md={23} sm={23} xs={23}>
                <Breadcrumb separator='>'>
                  <Breadcrumb.Item
                    onClick={() => handleCloseRestaurant()}
                    className='breadcrumRestaurant'
                  >
                    Restaurants
                  </Breadcrumb.Item>
                  <Breadcrumb.Item
                    onClick={() => handleCloseRestaurantDrawer()}
                    className='breadcrumRestaurant'
                  >
                    {restaurant?.name}
                  </Breadcrumb.Item>
                  <Breadcrumb.Item className='breadcrumUsers'>
                    Categories
                  </Breadcrumb.Item>
                </Breadcrumb>
              </Col>
              <Col span={6}>
                <Typography className='heading'>Categories</Typography>
              </Col>

              <Col xl={18} lg={18} md={24} sm={24} xs={24}>
                <Row justify='end' gutter={[8, 8]}>
                  <Col span={12}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'end',
                        alignItems: 'center',
                        fontFamily: 'Circular-400',
                      }}
                    >
                      <Button
                        type='primary'
                        danger
                        onClick={deleteRows}
                        size='medium'
                        disabled={selectedRowKeys.length === 0}
                        style={{ borderRadius: '5px' }}
                      >
                        Delete Selected
                      </Button>
                    </div>
                  </Col>
                  <Col>
                    <Button
                      className='detailsButton'
                      onClick={handleCreate}
                      icon={<img src={plus} alt='props' />}
                    >
                      Add New Category
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col>
                <CategoryDetails
                  setDrawerVisible={setDrawerVisible}
                  drawerVisible={drawerVisible}
                  isModalVisible={isModalVisible}
                  setIsModalVisible={setIsModalVisible}
                  setEditId={setEditId}
                  editId={editId}
                  categoryDetailId={categoryDetailId}
                  currentPage={currentPage}
                ></CategoryDetails>
              </Col>
              <Col span={24}>
                <CustomTable
                  type='category'
                  columns={Columns}
                  rowSelection={rowSelection}
                  currentPage={currentPage}
                  selectedRow={selectedRow}
                  setSelectedRow={setSelectedRow}
                  data={sortOrder === 'asc' ? allCategory : sortedData}
                />
              </Col>

              {allCategory && length > 0 ? (
                <Col span={24}>
                  <Row justify='center'>
                    <Pagination
                      total={allCategory?.length}
                      onChange={(e) => setCurrentPage(e)}
                      responsive={true}
                      size='large'
                      current={currentPage}
                      showSizeChanger={false}
                    />
                  </Row>
                </Col>
              ) : (
                ''
              )}
            </Row>
          )}
        </Col>
      </Row>
    </Spin>
  );
};

export default Category;
