import constants from '../Utils/constants';
const initialState = {
  isCategoryloading: false,
  allCategory: [],
  isCreateCategoryoading: false,
  isDeleteCategoryLoading: false,
  isUpdateCategoryLoading: false,
  setCategoryId: '',
  Category: {},
  isCategoryByIdLoading: false,
};

export const categoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_CATEGORY_REQUEST:
      return {
        ...state,
        isCategoryloading: true,
      };
    case constants.GET_CATEGORY_SUCCESS:
      return {
        ...state,
        isCategoryloading: false,
        allCategory: action.payload,
      };
    case constants.GET_CATEGORY_FAILURE:
      return {
        ...state,
        isCategoryloading: false,
      };
    case constants.CREATE_CATEGORY_REQUEST:
      return {
        ...state,
        isCreateCategoryoading: true,
      };
    case constants.CREATE_CATEGORY_SUCCESS:
      return {
        ...state,
        isCreateCategoryoading: false,
      };
    case constants.CREATE_CATEGORY_FAILURE:
      return {
        ...state,
        isCreateCategoryoading: false,
      };
    case constants.DELETE_CATEGORY_REQUEST:
      return {
        ...state,
        isDeleteCategoryLoading: true,
      };
    case constants.DELETE_CATEGORY_SUCCESS:
      return {
        ...state,
        isDeleteCategoryLoading: false,
      };
    case constants.DELETE_CATEGORY_FAILURE:
      return {
        ...state,
        isDeleteCategoryLoading: false,
      };
    case constants.DELETE_MULTI_CATEGORY_REQUEST:
      return {
        ...state,
        isDeleteCategoryLoading: true,
      };
    case constants.DELETE_MULTI_CATEGORY_SUCCESS:
      return {
        ...state,
        isDeleteCategoryLoading: false,
      };
    case constants.DELETE_MULTI_CATEGORY_FAILURE:
      return {
        ...state,
        isDeleteCategoryLoading: false,
      };
    case constants.UPDATE_CATEGORY_REQUEST:
      return {
        ...state,
        isUpdateCategoryLoading: true,
      };
    case constants.UPDATE_CATEGORY_SUCCESS:
      return {
        ...state,
        isUpdateCategoryLoading: false,
      };
    case constants.UPDATE_CATEGORY_FAILURE:
      return {
        ...state,
        isUpdateCategoryLoading: false,
      };
    // case constants.REORDER_CATEGORY_REQUEST:
    //   return {
    //     ...state,
    //     isUpdateCategoryLoading: true,
    //   };
    // case constants.REORDER_CATEGORY_SUCCESS:
    //   return {
    //     ...state,
    //     isUpdateCategoryLoading: false,
    //   };
    // case constants.REORDER_CATEGORY_FAILURE:
    //   return {
    //     ...state,
    //     isUpdateCategoryLoading: false,
    //   };
    case constants.GET_CATEGORY_BY_ID_REQUEST:
      return {
        ...state,
        isCategoryByIdLoading: true,
      };
    case constants.GET_CATEGORY_BY_ID_SUCCESS:
      return {
        ...state,
        isCategoryByIdLoading: false,
        Category: action.payload,
      };
    case constants.GET_CATEGORY_BY_ID_FAILURE:
      return {
        ...state,
        isCategoryByIdLoading: false,
      };
    case constants.GET_SELECTED_CATEGORY_ID:
      return {
        ...state,
        setCategoryId: action.payload,
      };
    default:
      return state;
  }
};
