import React, { useEffect, useState } from 'react';
import './Restaurant.css';
import {
  Button,
  Row,
  Col,
  Typography,
  Spin,
  message,
  Card,
  Checkbox,
  Switch,
  Tabs,
  Input,
  Select,
} from 'antd';
import {
  updateCapabilitiesRestaurantAction,
  getRestaurantDetails,
  getRestaurant,
  getRestaurantById,
} from '../../Actions/RestaurantAction';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import _ from 'lodash';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import TabPane from 'antd/lib/tabs/TabPane';
import ScheduledPickup from './ScheduledPickup';
import { useParams } from 'react-router';
const Capabilities = (props) => {
  const {
    editId,
    setEditId,
    setIsDefaultImageVisible,
    setShowUploadImage,
    setTabkey,
    currentPage,
  } = props;
  const dispatch = useDispatch();
  const { orgId } = useParams();
  const [newFiles, setNewFiles] = useState();
  const IsEmpty = _.isEmpty(newFiles) ? true : false;
  const [preloadedRestaurantValue, setPreloadedRestaurantValue] = useState('');
  const [selected, setSelected] = useState('72hrs');
  const [selectedAddressOption, setSelectedAddressOption] = useState(
    'Use organization address'
  );

  const {
    allRestaurant,
    restaurant,
    isCreateRestaurantLoading,
    isUpdateRestaurantLoading,
  } = useSelector((state) => {
    const {
      allRestaurant,
      restaurant,
      isCreateRestaurantLoading,
      isUpdateRestaurantLoading,
    } = state.restaurant;
    return {
      allRestaurant,
      restaurant,
      isCreateRestaurantLoading,
      isUpdateRestaurantLoading,
    };
  });

  const { organizations } = allRestaurant;
  const options = ['12hrs', '24hrs', '48hrs', '72hrs'];
  const addressOptions = ['Use organization address', 'Add custom address'];
  // const the_restaurant = _.filter(organizations, function (o) {
  //   return o.id === editId;
  // });
  const { handleSubmit, control, reset, clearErrors } = useForm({
    defaultValues: {},
  });
  const [activeKey, setActiveKey] = useState('1');
  const [isVeg, setisVeg] = useState(
    editId && restaurant?.is_veg ? true : false
  );
  const [checkedValueDineIn, setCheckedValueDineIn] = useState(
    editId && restaurant?.is_dine_in ? true : false
  );
  const [checkedValueDineInPrePaid, setCheckedValueDineInPrePaid] = useState(
    editId && restaurant?.dinein_prepaid ? true : false
  );
  const [checkedValueDineInPostPaid, setCheckedValueDineInPostPaid] = useState(
    editId && restaurant?.dinein_pospaid ? true : false
  );
  const [checkedValueTakeAway, setCheckedValueTakeAway] = useState(
    editId && restaurant?.is_take_away ? true : false
  );
  const [
    checkedValueTakeAwayOnlinePayment,
    setCheckedValueTakeAwayOnlinePayment,
  ] = useState(editId && restaurant?.take_away_online_payment ? true : false);
  const [checkedValueTakeAwayCashPayment, setCheckedValueTakeAwayCashPayment] =
    useState(editId && restaurant?.take_away_cash_payment ? true : false);
  const [checkedValueDelivery, setCheckedValueDelivery] = useState(
    editId && restaurant?.is_delivery ? true : false
  );
  const [checkedValueCashOnDelivery, setCheckedValueCashOnDelivery] = useState(
    editId && restaurant?.delivery_cash_on_delivery ? true : false
  );
  const [checkedValueScheduledPickup, setCheckedValueScheduledPickup] =
    useState(editId && restaurant?.scheduled_pickup ? true : false);
  const [
    checkedValueCashOnScheduledPickup,
    setCheckedValueCashOnScheduledPickup,
  ] = useState(
    editId && restaurant?.scheduled_pickup_cash_payment ? true : false
  );
  const [
    checkedValueOnlinePaymentScheduledPickup,
    setCheckedValueOnlinePaymentScheduledPickup,
  ] = useState(
    editId && restaurant?.scheduled_pickup_online_payment ? true : false
  );

  const [
    checkedValueDeliveryOnlinePayment,
    setCheckedValueDeliveryOnlinePayment,
  ] = useState(editId && restaurant?.delivery_online_payment ? true : false);
  const [isDineInVisible, setIsDineInVisible] = useState(false);

  const handleDineInClick = () => {
    setIsDineInVisible((prev) => !prev);
  };
  const [isTakeAwayVisible, setIsTakeAwayVisible] = useState(false);

  const handleTakeAwayClick = () => {
    setIsTakeAwayVisible((prev) => !prev);
  };
  const [isDelieveryVisible, setIsDelieveryVisible] = useState(false);

  const handleDelieveryClick = () => {
    setIsDelieveryVisible((prev) => !prev);
  };

  const [isSchedulePickupVisible, setIsSchedulePickupVisible] = useState(false);

  const handleSchedulePickupClick = () => {
    setIsSchedulePickupVisible((prev) => !prev);
  };
  const handleisVegMethod = (e) => {
    setisVeg(e);
  };
  const onChangeDineIn = (e) => {
    setCheckedValueDineIn(e);
  };
  const onChangeDineInPrePaid = (e) => {
    setCheckedValueDineInPrePaid(e);
  };
  const onChangeDineInPostPaid = (e) => {
    setCheckedValueDineInPostPaid(e);
  };
  const onChangeTakeaway = (e) => {
    setCheckedValueTakeAway(e);
  };
  const onChangeTakeawayOnlinePayment = (e) => {
    setCheckedValueTakeAwayOnlinePayment(e);
  };
  const onChangeTakeawayCashPayment = (e) => {
    setCheckedValueTakeAwayCashPayment(e);
  };
  const onChangeDelivery = (e) => {
    setCheckedValueDelivery(e);
  };
  const onChangeCashOnDelivery = (e) => {
    setCheckedValueCashOnDelivery(e);
  };
  const onChangeDeliveryOnlinePayment = (e) => {
    setCheckedValueDeliveryOnlinePayment(e);
  };
  const onChangeScheduledPickup = (e) => {
    setCheckedValueScheduledPickup(e);
  };
  const onChangeCashOnScheduledPickup = (e) => {
    setCheckedValueCashOnScheduledPickup(e);
  };
  const onChangeOnlinePaymentScheduledPickup = (e) => {
    setCheckedValueOnlinePaymentScheduledPickup(e);
  };
  const handleCancel = () => {
    reset(preloadedRestaurantValue);
    setEditId('');
    setNewFiles([]);
    setShowUploadImage(false);
    setIsDefaultImageVisible(true);
    clearErrors();
  };

  const handleEditRestaurantMethod = (data) => {
    data.id = editId;
    data.is_veg = isVeg;
    data.is_dine_in = checkedValueDineIn;
    data.is_take_away = checkedValueTakeAway;
    data.is_delivery = checkedValueDelivery;
    data.dinein_prepaid = checkedValueDineInPrePaid;
    data.dinein_pospaid = checkedValueDineInPostPaid;
    data.take_away_online_payment = checkedValueTakeAwayOnlinePayment;
    data.take_away_cash_payment = checkedValueTakeAwayCashPayment;
    data.delivery_cash_on_delivery = checkedValueCashOnDelivery;
    data.delivery_online_payment = checkedValueDeliveryOnlinePayment;
    data.scheduled_pickup_cash_payment = checkedValueCashOnScheduledPickup;
    data.scheduled_pickup_online_payment =
      checkedValueOnlinePaymentScheduledPickup;
    data.scheduled_pickup = checkedValueScheduledPickup;
    dispatch(
      updateCapabilitiesRestaurantAction(
        data,
        UpdateCallBack,
        faliureUpdate,
        IsEmpty
      )
    );
  };

  const UpdateCallBack = () => {
    setTabkey('1');
    dispatch(getRestaurantById(editId));
    setTimeout(() => {
      message.success('Your Restaurant Updated Successfully');
    }, 1000);
  };
  const faliureUpdate = (failureResponse) => {
    setTimeout(() => {
      message.error(failureResponse);
    }, 1000);
  };

  const isLoading = isCreateRestaurantLoading || isUpdateRestaurantLoading;
  return (
    <Spin spinning={isLoading}>
      <Card
        style={{
          backgroundColor: '#f6f6f6',
          border: 'rgb(229, 229, 229,1)',
        }}
      >
        <Row gutter={[16, 16]} className='button'>
          <Col xl={16} lg={16} md={16} sm={24} xs={24} className='formRows'>
            <Row>
              <Col span={1}>
                <Controller
                  name='is_veg'
                  control={control}
                  defaultValue={false}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={isVeg}
                      onChange={(e) => handleisVegMethod(e.target.checked)}
                    />
                  )}
                />
              </Col>
              <Col span={22}>
                <Typography className='formHeading'>
                  Does this restaurant serves only vegetarian food&nbsp;?
                </Typography>
              </Col>
            </Row>
            <Tabs
              activeKey={activeKey}
              onChange={setActiveKey}
              type='line'
              className='formHeading'
              style={{ marginTop: '30px' }}
            >
              {/* Dine In Tab */}
              <TabPane tab='Dine In' key='1'>
                <form
                  onSubmit={handleSubmit(handleEditRestaurantMethod)}
                  // style={{
                  //   maxHeight: '500px',
                  //   overflowX: 'hidden',
                  // }}
                >
                  <Card>
                    <Row gutter={[18, 18]} justify='space-between'>
                      <Col span={20}>
                        <Typography className='formHeading'>Dine In</Typography>
                      </Col>
                      <Col span={4} align='end'>
                        <Controller
                          name='is_dine_in'
                          control={control}
                          defaultValue={false}
                          render={(props) => (
                            <Checkbox
                              {...props}
                              checked={checkedValueDineIn}
                              onChange={(e) => onChangeDineIn(e.target.checked)}
                            />
                          )}
                        />
                      </Col>
                      <Col span={24}>
                        <Typography className='noteText'>
                          Note: Turn on the billing types according to your
                          needs.(Turn on any one)
                        </Typography>
                      </Col>
                      <Col span={24}>
                        <Row gutter={[0, 8]}>
                          <Col span={20}>
                            <Typography className='formHeading'>
                              Prepaid
                            </Typography>
                          </Col>
                          <Col span={4} align='end'>
                            <Switch
                              checked={checkedValueDineInPrePaid}
                              onChange={onChangeDineInPrePaid}
                              size='small'
                            />
                          </Col>
                          <Col span={24}>
                            <Typography className='infoText'>
                              Prepaid means customers pay for their order
                              upfront before receiving the service or meal
                            </Typography>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24}>
                        <Row gutter={[0, 8]}>
                          <Col span={20}>
                            <Typography className='formHeading'>
                              Postpaid
                            </Typography>
                          </Col>
                          <Col span={4} align='end'>
                            <Switch
                              checked={checkedValueDineInPostPaid}
                              onChange={onChangeDineInPostPaid}
                              size='small'
                            />
                          </Col>
                          <Col span={24}>
                            <Typography className='infoText'>
                              Postpaid allows them to pay after they have
                              finished dining or received their service.
                            </Typography>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row gutter={[22, 22]} className='button' justify='center'>
                      <Col span={24}></Col>
                      <Col xl={4} lg={8} md={8} sm={10} xs={10}>
                        <Button className='cancelButton' onClick={handleCancel}>
                          Cancel
                        </Button>
                      </Col>
                      <Col xl={4} lg={8} md={8} sm={10} xs={10}>
                        <Button
                          className='editDesignationButton'
                          htmlType='submit'
                        >
                          Update
                        </Button>
                      </Col>
                    </Row>
                  </Card>
                </form>
              </TabPane>

              {/* Take Away Tab */}
              <TabPane tab='Take Away' key='2'>
                <form
                  onSubmit={handleSubmit(handleEditRestaurantMethod)}
                  // style={{
                  //   maxHeight: '500px',
                  //   overflowX: 'hidden',
                  // }}
                >
                  <Card>
                    <Row gutter={[18, 18]} justify='space-between'>
                      <Col span={20}>
                        <Typography className='formHeading'>
                          Take Away
                        </Typography>
                      </Col>
                      <Col span={4} align='end'>
                        <Controller
                          name='is_take_away'
                          control={control}
                          defaultValue={false}
                          render={(props) => (
                            <Checkbox
                              {...props}
                              checked={checkedValueTakeAway}
                              onChange={(e) =>
                                onChangeTakeaway(e.target.checked)
                              }
                            />
                          )}
                        />
                      </Col>
                      <Col span={24}>
                        <Typography className='noteText'>
                          Note: Turn on the billing types according to your
                          needs
                        </Typography>
                      </Col>
                      <Col span={24}>
                        <Row gutter={[0, 8]}>
                          <Col span={20}>
                            <Typography className='formHeading'>
                              Cash Payment
                            </Typography>
                          </Col>
                          <Col span={4} align='end'>
                            <Switch
                              checked={checkedValueTakeAwayCashPayment}
                              onChange={onChangeTakeawayCashPayment}
                              size='small'
                            />
                          </Col>
                          <Col span={24}>
                            <Typography className='infoText'>
                              Customers pay for their takeaway order online or
                              in advance before arriving at the restaurant to
                              collect it.
                            </Typography>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24}>
                        <Row gutter={[0, 8]}>
                          <Col span={20}>
                            <Typography className='formHeading'>
                              Online Payment
                            </Typography>
                          </Col>
                          <Col span={4} align='end'>
                            <Switch
                              checked={checkedValueTakeAwayOnlinePayment}
                              onChange={onChangeTakeawayOnlinePayment}
                              size='small'
                            />
                          </Col>
                          <Col span={24}>
                            <Typography className='infoText'>
                              Customers place their takeaway order and pay at
                              the restaurant when they come to pick it up.
                            </Typography>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row gutter={[22, 22]} className='button' justify='center'>
                      <Col span={24}></Col>
                      <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                        <Button className='cancelButton' onClick={handleCancel}>
                          Cancel
                        </Button>
                      </Col>
                      <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                        <Button
                          className='editDesignationButton'
                          htmlType='submit'
                        >
                          Update
                        </Button>
                      </Col>
                    </Row>
                  </Card>
                </form>
              </TabPane>

              {/* Delivery Tab */}
              <TabPane tab='Delivery' key='3'>
                <form
                  onSubmit={handleSubmit(handleEditRestaurantMethod)}
                  // style={{
                  //   maxHeight: '500px',
                  //   overflowX: 'hidden',
                  // }}
                >
                  <Card>
                    <Row gutter={[18, 18]} justify='space-between'>
                      <Col span={20}>
                        <Typography className='formHeading'>
                          Delivery
                        </Typography>
                      </Col>
                      <Col span={4} align='end'>
                        <Controller
                          name='is_delivery'
                          control={control}
                          defaultValue={false}
                          render={(props) => (
                            <Checkbox
                              {...props}
                              checked={checkedValueDelivery}
                              onChange={(e) =>
                                onChangeDelivery(e.target.checked)
                              }
                            />
                          )}
                        />
                      </Col>
                      <Col span={24}>
                        <Typography className='noteText'>
                          Note: Turn on the payment options according to your
                          needs
                        </Typography>
                      </Col>
                      <Col span={24}>
                        <Row gutter={[0, 8]}>
                          <Col span={20}>
                            <Typography className='formHeading'>
                              Cash on Delivery
                            </Typography>
                          </Col>
                          <Col span={4} align='end'>
                            <Switch
                              checked={checkedValueCashOnDelivery}
                              onChange={onChangeCashOnDelivery}
                              size='small'
                            />
                          </Col>
                          <Col span={24}>
                            <Typography className='infoText'>
                              Customers pay immediately through digital methods
                              when placing the order.
                            </Typography>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24}>
                        <Row gutter={[0, 8]}>
                          <Col span={20}>
                            <Typography className='formHeading'>
                              Online Payment
                            </Typography>
                          </Col>
                          <Col span={4} align='end'>
                            <Switch
                              checked={checkedValueDeliveryOnlinePayment}
                              onChange={onChangeDeliveryOnlinePayment}
                              size='small'
                            />
                          </Col>
                          <Col span={24}>
                            <Typography className='infoText'>
                              Customers can choose to pay in cash when the order
                              is delivered to them.
                            </Typography>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row gutter={[22, 22]} className='button' justify='center'>
                      <Col span={24}></Col>
                      <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                        <Button className='cancelButton' onClick={handleCancel}>
                          Cancel
                        </Button>
                      </Col>
                      <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                        <Button
                          className='editDesignationButton'
                          htmlType='submit'
                        >
                          Update
                        </Button>
                      </Col>
                    </Row>
                  </Card>
                </form>
              </TabPane>

              {/* Scheduled Pickup Tab */}
              <TabPane tab='Scheduled Pickup' key='4'>
                <ScheduledPickup
                  editId={editId}
                  restaurant={restaurant}
                  setTabkey={setTabkey}
                />
              </TabPane>
            </Tabs>
          </Col>
        </Row>
      </Card>
    </Spin>
  );
};

export default Capabilities;
