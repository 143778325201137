import constants from '../Utils/constants';
const initialState = {
  isQueueLoading: false,
  isQueueByIdLoading: false,
  allQueue: [],
  Queue: {},
};

export const queueReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_QUEUE_REQUEST:
      return {
        ...state,
        isQueueLoading: true,
      };
    case constants.GET_QUEUE_SUCCESS:
      return {
        ...state,
        isQueueLoading: false,
        allQueue: action.payload,
      };
    case constants.GET_QUEUE_FAILURE:
      return {
        ...state,
        isQueueLoading: false,
      };
    case constants.GET_QUEUE_BY_ID_REQUEST:
      return {
        ...state,
        isQueueByIdLoading: true,
      };
    case constants.GET_QUEUE_BY_ID_SUCCESS:
      return {
        ...state,
        isQueueByIdLoading: false,
        Queue: action.payload,
      };
    case constants.GET_QUEUE_BY_ID_FAILURE:
      return {
        ...state,
        isQueueByIdLoading: false,
      };
    default:
      return state;
  }
};
