import React, { useEffect, useState } from 'react';
import {
  Space,
  Row,
  Col,
  Typography,
  message,
  Button,
  Input,
  Card,
  Spin,
  Select,
} from 'antd';
import { useSelector } from 'react-redux';
import './Restaurant.css';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import { Controller, useForm } from 'react-hook-form';
import { LuAsterisk } from 'react-icons/lu';
import {
  updateRestaurantDetails,
  getRestaurantById,
  getRestaurantDetails,
} from '../../Actions/RestaurantAction';
import PhoneInput from 'react-phone-input-2';
import RestaurantDetails from './RestaurantDetails';
const Location = (props) => {
  const {
    restaurantDetailId,
    setrestaurantDetailId,
    setTabkey,
    editId,
    setEditId,
    isType,
  } = props;
  const dispatch = useDispatch();
  const {
    restaurantdeatils,
    restaurantdetailsId,
    restaurant,
    setRestaurantId,
    isUpdateRestaurantLoading,
  } = useSelector((state) => {
    const {
      restaurantdeatils,
      restaurant,
      restaurantdetailsId,
      setRestaurantId,
      isUpdateRestaurantLoading,
    } = state.restaurant;
    return {
      restaurantdeatils,
      restaurant,
      setRestaurantId,
      restaurantdetailsId,
      isUpdateRestaurantLoading,
    };
  });
  const [newFiles, setNewFiles] = useState([]);
  const [countryCode, setCountryCode] = useState('');
  const [displayNumber, setDisplayNumber] = useState('');
  const { Option } = Select;
  const IsEmpty = _.isEmpty(newFiles);
  const [preloadedRestaurantValue, setPreloadedRestaurantValue] = useState('');
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    clearErrors,
  } = useForm({
    defaultValues: {
      address_line_1: restaurantdeatils?.address_line_1,
      address_line_2: restaurantdeatils?.address_line_2,
      city: restaurantdeatils?.city,
      zip_code: restaurantdeatils?.zip_code,
      phone: restaurantdeatils?.phone,
      email: restaurantdeatils?.email,
      fax: restaurantdeatils?.fax,
    },
  });

  const [number, setNumber] = useState(
    editId &&
      restaurantdeatils?.phone &&
      restaurantdeatils?.phone.replace(/\D/g, '').slice(-10)
  );

  useEffect(() => {
    if (!editId) {
      setEditId('');
      setValue('address_line_1', '');
      setValue('address_line_2', '');
      setValue('city', '');
      setValue('zip_code', '');
    }
  }, []);

  const phoneNumberMethod = (value, country, e, formattedValue) => {
    setCountryCode(country.dialCode);
    setNumber(formattedValue);
    setDisplayNumber(formattedValue);
  };

  const handleEditRestaurantMethod = (data) => {
    data.id = restaurantdeatils?.id;
    data.phone = number;
    dispatch(updateRestaurantDetails(data, UpdateCallBack, faliureUpdate));
  };
  const UpdateCallBack = () => {
    setTabkey('1');
    setTimeout(() => {
      message.success('Your Restaurant Updated Successfully');
    }, 1000);
  };
  const faliureUpdate = (failureResponse) => {
    setTimeout(() => {
      message.error(failureResponse);
    }, 1000);
  };
  const handleClose = () => {
    reset(preloadedRestaurantValue);
  };
  const inputStyles = {
    WebkitBoxShadow: '0 0 0 30px #f6f6f6 inset',
  };
  const handleChange = (e) => {
    e.target.value = setNumber(e.target.value.replace(/\D/g, ''));
  };

  return (
    <Spin spinning={isUpdateRestaurantLoading}>
      <Card
        style={{
          backgroundColor: '#f6f6f6',
          border: 'rgb(229, 229, 229,1)',
        }}
        // scrollable={{ x: 1000, y:500 }}
      >
        <form
          // className="form"
          onSubmit={handleSubmit(handleEditRestaurantMethod)}
          style={{
            height: '100%',
            width: '100%',
            overflowY: 'auto',
            overflowX: 'hidden',
          }}
        >
          <Row gutter={[16, 16]} className='button'>
            <Col xl={16} lg={16} md={16} sm={24} xs={24} className='formRows'>
              <Typography className='formHeading'>
                Address line 1 &nbsp;
              </Typography>
              <Controller
                as={<Input style={inputStyles}></Input>}
                name='address_line_1'
                control={control}
                className='inputLabel'
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]} className='button'>
            <Col xl={16} lg={16} md={16} sm={24} xs={24} className='formRows'>
              <Typography className='formHeading'>
                Address line 2 &nbsp;
              </Typography>
              <Controller
                as={<Input style={inputStyles}></Input>}
                name='address_line_2'
                control={control}
                className='inputLabel'
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]} className='button'>
            <Col xl={8} lg={8} md={8} sm={24} xs={24} className='formRows'>
              <Typography className='formHeading'>City &nbsp;</Typography>
              <Controller
                as={<Input style={inputStyles}></Input>}
                name='city'
                control={control}
                className='inputLabel'
              />
            </Col>

            <Col xl={8} lg={8} md={8} sm={24} xs={24} className='formRows'>
              <Typography className='formHeading'>Zip Code &nbsp;</Typography>
              <Controller
                as={<Input style={inputStyles}></Input>}
                name='zip_code'
                control={control}
                className='inputLabel'
              />
            </Col>
          </Row>
          {editId && isType === 'updateForm' ? (
            <>
              <Row gutter={[16, 16]} className='button'>
                <Col
                  xl={16}
                  lg={16}
                  md={16}
                  sm={24}
                  xs={24}
                  className='formRows'
                >
                  <Typography className='formHeading'>Mobile Number</Typography>
                  <Controller
                    render={() => (
                      <Input
                        className='inputLabel'
                        maxLength={10}
                        onChange={handleChange}
                        defaultValue={number}
                      />
                    )}
                    name='phone'
                    control={control}
                  />
                </Col>
              </Row>
              <Row gutter={[16, 16]} className='button'>
                <Col
                  xl={16}
                  lg={16}
                  md={16}
                  sm={24}
                  xs={24}
                  className='formRows'
                >
                  <Typography className='formHeading'>Email</Typography>
                  <Controller
                    as={<Input style={inputStyles} />}
                    name='email'
                    control={control}
                    defaultValue={editId ? restaurantdeatils?.email : ''}
                    className='inputLabel'
                  />
                  {errors.email && (
                    <p style={{ color: 'red' }}>{errors.email.message}</p>
                  )}
                </Col>
              </Row>
              <Row gutter={[16, 16]} className='button'>
                <Col xl={8} lg={8} md={8} sm={24} xs={24} className='formRows'>
                  <Typography className='formHeading'>Country</Typography>
                  <Input
                    defaultValue={editId && restaurantdeatils?.country}
                    disabled
                  />
                </Col>
                <Col xl={8} lg={8} md={8} sm={24} xs={24} className='formRows'>
                  <Typography className='formHeading'>State</Typography>
                  <Input
                    defaultValue={editId && restaurantdeatils?.state}
                    disabled
                  />
                </Col>
                <Col
                  xl={8}
                  lg={8}
                  md={8}
                  sm={24}
                  xs={24}
                  className='formRows'
                ></Col>
              </Row>
              <Row gutter={[16, 16]} className='button'>
                <Col
                  xl={16}
                  lg={16}
                  md={16}
                  sm={24}
                  xs={24}
                  className='formRows'
                >
                  <Typography className='formHeading'>Time Zone</Typography>
                  <Input
                    defaultValue={
                      editId && restaurantdeatils?.organization_time_zone
                    }
                    disabled
                  />
                </Col>
              </Row>

              <Row gutter={[16, 16]} className='button'>
                <Col
                  xl={16}
                  lg={16}
                  md={16}
                  sm={24}
                  xs={24}
                  className='formRows'
                >
                  <Typography className='formHeading'>Fax Number</Typography>
                  <Controller
                    style={{ border: '10px solid yellow' }}
                    as={<Input style={inputStyles} />}
                    name='fax'
                    control={control}
                    className='inputLabel'
                    defaultValue={editId ? restaurantdeatils?.fax : ''}
                  />
                </Col>
              </Row>
            </>
          ) : (
            ''
          )}

          <Row gutter={[22, 22]} className='button' justify='center'>
            <Col span={24}></Col>
            <Col xl={4} lg={4} md={4} sm={12} xs={12}>
              <Button
                // className="filterButton"
                className='cancelButton'
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Col>
            <Col xl={4} lg={4} md={4} sm={12} xs={12}>
              <Button
                className='editDesignationButton'
                htmlType='submit'
                // onClick={handleEditRestaurantMethod}
              >
                Update
              </Button>
            </Col>
            <Col xl={8} lg={8} md={8} sm={12} xs={12}></Col>
          </Row>
        </form>
      </Card>
    </Spin>
  );
};
export default Location;
