import API from '../Utils/API';

export const getSchedulePickup = (params) => (dispatch) => {
  dispatch({ type: 'GET_SCHEDULE_PICKUP_REQUEST' });
  const apiUrl = params
    ? `api/organization_schedule_pickups${params}`
    : 'api/organization_schedule_pickups';
  API.get(apiUrl)
    .then((response) => {
      dispatch({ type: 'GET_SCHEDULE_PICKUP_SUCCESS', payload: response.data });
    })
    .catch((error) => {
      try {
        dispatch({
          type: 'GET_SCHEDULE_PICKUP_FAILURE',
          payload: error.response.data,
        });
      } catch {}
    });
};

export const getSchedulePickupById = (Id) => (dispatch) => {
  dispatch({ type: 'GET_SCHEDULE_PICKUP_BY_ID_REQUEST' });
  API.get(`api/organization_schedule_pickups/${Id}`)
    .then((response) => {
      dispatch({
        type: 'GET_SCHEDULE_PICKUP_BY_ID_SUCCESS',
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: 'GET_SCHEDULE_PICKUP_BY_ID_FAILURE',
        payload: error?.response.data,
      });
    });
};

export const updateSchedulPickup =
  (data, successUpdateCallBack, failureUpdateCallBack) => (dispatch) => {
    dispatch({ type: 'UPDATE_SCHEDULE_PICKUP_REQUEST' });
    API.put(`api/organization_schedule_pickups/${data.id}`, data)
      .then((response) => {
        dispatch({
          type: 'UPDATE_SCHEDULE_PICKUP_SUCCESS',
          payload: response.data,
        });
        successUpdateCallBack && successUpdateCallBack();
      })
      .catch((error) => {
        dispatch({
          type: 'UPDATE_SCHEDULE_PICKUP_FAILURE',
          payload: error.response?.data,
        });
        failureUpdateCallBack && failureUpdateCallBack(error.response?.data);
      });
  };
