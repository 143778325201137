import React, { useEffect, useState } from 'react';
import {
  Button,
  Row,
  Col,
  Typography,
  Spin,
  Input,
  message,
  Breadcrumb,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import _ from 'lodash';
import { categorySchema } from '../../Utils/Schema';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  getCategory,
  createCategory,
  updateCategory,
} from '../../Actions/CategoryAction';
import { useNavigate, useParams } from 'react-router';
const CategoryModalForm = (props) => {
  const {
    editId,
    setEditId,
    setIsModalVisible,
    setCurrentPage,
    currentPage,
    restaurant,
  } = props;
  const dispatch = useDispatch();
  const { orgId } = useParams();
  const navigate = useNavigate();
  const [preloadedCategoryValue, setPreloadedCategoryValue] = useState('');
  const {
    allCategory,
    isCategoryloading,
    isCreateCategoryoading,
    isUpdateCategoryLoading,
  } = useSelector((state) => {
    const {
      allCategory,
      isCategoryloading,
      isCreateCategoryoading,
      isUpdateCategoryLoading,
    } = state.category;
    return {
      allCategory,
      isCategoryloading,
      isCreateCategoryoading,
      isUpdateCategoryLoading,
    };
  });
  const the_category = _.filter(allCategory, function (o) {
    return o.id === editId;
  });
  const {
    handleSubmit,
    formState: { errors, isDirty },
    control,
    reset,
    setValue,
    clearErrors,
  } = useForm({
    defaultValues: {
      preloadedCategoryValue,
    },
    resolver: yupResolver(categorySchema),
  });
  useEffect(() => {
    if (editId) {
      setPreloadedCategoryValue(the_category[0]);
      reset(the_category[0]);
    }
  }, [the_category[0], reset]);

  const handleCloseRestaurant = () => {
    navigate('/restaurants');
    dispatch({
      type: 'GET_SELECTED_RESTAURANT_ID',
      payload: '',
    });
  };
  const handleCloseRestaurantDrawer = () => {
    navigate(`/restaurants/${orgId}`);
  };

  const handleClose = () => {
    setIsModalVisible(false);
    setEditId('');
    setValue('name', '');
    setValue('published', '');
    clearErrors();
  };
  const handleCancel = () => {
    setEditId('');
    setValue('name', '');
    clearErrors();
  };
  const handleeditCancel = () => {
    reset(preloadedCategoryValue);
    clearErrors();
  };
  const handleCreate = (data) => {
    data.published = false;
    dispatch(createCategory(data, successCreate, failureCreate));
  };
  const successCreate = () => {
    setCurrentPage(1);
    reset();
    setEditId('');
    setIsModalVisible(false);
    dispatch(getCategory(`api/categories?page=${currentPage}`));
    setValue('name', '');
    setValue('published', '');
    clearErrors();
    setTimeout(() => {
      message.success('Your Category Created Successfully');
    }, 1000);
  };

  const failureCreate = (failureResponse) => {
    setTimeout(() => {
      message.error(failureResponse);
    }, 1000);
  };
  const handleEdit = (data) => {
    data.id = editId;
    dispatch(updateCategory(data, UpdateCallBack, faliureUpdate));
  };
  const UpdateCallBack = () => {
    reset();
    setCurrentPage(1);
    setIsModalVisible(false);
    setEditId('');
    setValue('name', '');
    setValue('published', '');
    dispatch(getCategory(`api/categories?page=${currentPage}`));
    clearErrors();
    setTimeout(() => {
      message.success('Your Category Updated Successfully');
    }, 1000);
  };
  const faliureUpdate = (failureResponse) => {
    setTimeout(() => {
      message.error(failureResponse);
    }, 1000);
  };

  useEffect(() => {
    if (!editId) {
      setEditId('');
    }
  }, [setValue, !editId]);
  const [width] = useState(window.innerWidth);

  return (
    <div>
      <Row gutter={[24, 24]}>
        <Col xl={23} lg={23} md={23} sm={23} xs={23}>
          <Breadcrumb separator='>'>
            <Breadcrumb.Item
              onClick={() => handleCloseRestaurant()}
              className='breadcrumRestaurant'
            >
              Restaurants
            </Breadcrumb.Item>
            <Breadcrumb.Item
              onClick={() => handleCloseRestaurantDrawer()}
              className='breadcrumRestaurant'
            >
              {restaurant?.name}
            </Breadcrumb.Item>
            <Breadcrumb.Item
              className='breadcrumRestaurant'
              onClick={() => handleClose()}
              style={{ cursor: 'pointer' }}
            >
              Categories
            </Breadcrumb.Item>
            <Breadcrumb.Item
              className='breadcrumUsers'
              onClick={() => handleClose()}
              style={{ cursor: 'pointer' }}
            >
              {editId ? 'Update Category' : 'Add New Category'}
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col span={18}>
          <Typography className='heading'>
            {editId ? 'Update Category' : 'Add New Category'}
          </Typography>
        </Col>
      </Row>
      <form onSubmit={handleSubmit(editId ? handleEdit : handleCreate)}>
        <Spin spinning={isCreateCategoryoading || isUpdateCategoryLoading}>
          <Row gutter={[16, 16]} className='button'>
            <Col span={16} className='formRows'>
              <Typography className='formHeading'>Category Name</Typography>
              <Controller
                as={<Input size='large' />}
                name='name'
                control={control}
                className='inputLabel'
              />
              {errors.name && (
                <p style={{ color: '#eb5757' }}>{errors.name.message}</p>
              )}
            </Col>
            <Col span={16}>
              <Row gutter={[16, 16]} className='button' justify='center'>
                <Col xl={6} lg={6} md={8} sm={12} xs={12}>
                  {editId ? (
                    <Button
                      className={isDirty ? 'cancelButton' : 'cancelButtonDirty'}
                      onClick={handleeditCancel}
                      size='large'
                      disabled={!isDirty}
                    >
                      Cancel
                    </Button>
                  ) : (
                    <Button
                      className='cancelButton'
                      onClick={handleCancel}
                      size='large'
                    >
                      Cancel
                    </Button>
                  )}
                </Col>
                <Col xl={6} lg={6} md={8} sm={12} xs={12}>
                  {editId ? (
                    <Button
                      size='large'
                      className={isDirty ? 'detailsButton' : 'classButton'}
                      htmlType='submit'
                      disabled={!isDirty}
                    >
                      Update
                    </Button>
                  ) : (
                    <Button
                      size='large'
                      className='detailsButton'
                      htmlType='submit'
                    >
                      Create
                    </Button>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>

          {/* <Row gutter={[22, 22]} className='button'>
            <Col xl={4} lg={4} md={8} sm={6} xs={6}>
              {editId ? (
                <Button
                  className='cancelButton'
                  onClick={handleeditCancel}
                  size='large'
                >
                  Cancel
                </Button>
              ) : (
                <Button
                  className='cancelButton'
                  onClick={handleCancel}
                  size='large'
                >
                  Cancel
                </Button>
              )}
            </Col>
            <Col xl={4} lg={4} md={8} sm={12} xs={12}>
              {editId ? (
                <Button
                  size='large'
                  className='detailsButton'
                  htmlType='submit'
                >
                  Update
                </Button>
              ) : (
                <Button
                  size='large'
                  className='detailsButton'
                  htmlType='submit'
                >
                  Create
                </Button>
              )}
            </Col>
          </Row> */}
        </Spin>
      </form>
      {/* </Spin> */}
    </div>
  );
};

export default CategoryModalForm;
