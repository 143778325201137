import React, { useState } from 'react';
import {
  Row,
  Col,
  Drawer,
  Typography,
  Spin,
  Divider,
  Timeline,
  Table,
} from 'antd';
import '../Restaurant/Restaurant.css';
import Back from '../../assests/Back.svg';

import { useSelector } from 'react-redux';
import _ from 'lodash';
import { MdAccessTimeFilled, MdTableRestaurant } from 'react-icons/md';
import { IoMdPerson } from 'react-icons/io';
import moment from 'moment';
import kidsChair from '../../assests/kidsChair.svg';
import bbqGrill from '../../assests/bbqGrill.svg';
import disabledSeat from '../../assests/disabledSeat.svg';
import { falseyValueCases } from '../../Screens/CommonLogics/CommonMethods';

const QueueDetails = (props) => {
  const { drawerVisible, setDrawerVisible, organizationTimezone, tabKey } =
    props;
  const [width] = useState(window.innerWidth);
  const handleCloseDrawer = () => {
    setDrawerVisible(false);
  };

  const { CaptainTable, isCaptainTableByIdLoading, Queue, isQueueByIdLoading } =
    useSelector((state) => {
      const { Queue, isQueueByIdLoading } = state.queue;
      const { CaptainTable, isCaptainTableByIdLoading } = state.tables;
      return {
        CaptainTable,
        Queue,
        isQueueByIdLoading,
        isCaptainTableByIdLoading,
      };
    });

  return (
    <Drawer
      closeIcon={<img src={Back} alt='props' />}
      className='customerAddressHeader'
      closable={false}
      title={
        <Row>
          <Col span={2}>
            <img
              src={Back}
              alt='props'
              onClick={handleCloseDrawer}
              style={{ cursor: 'pointer' }}
            />
          </Col>
          <Col span={22}>
            <Typography className='drawerHeading'>
              {tabKey === '3'
                ? CaptainTable?.table_number
                : _.split(Queue?.queue_number, '.')[1]}
            </Typography>
          </Col>
        </Row>
      }
      placement='right'
      open={drawerVisible}
      onClose={handleCloseDrawer}
      width='40%'
    >
      <Spin spinning={isQueueByIdLoading || isCaptainTableByIdLoading}>
        <Row style={{ marginTop: '10px' }}>
          <Col span={24}>
            <Row>
              <Col span={24}>
                {tabKey !== '3' && (
                  <Row className='queueActivityContainer'>
                    <Col span={24}>
                      <Row>
                        <Col span={24} className='centerText'>
                          <MdAccessTimeFilled fontSize='large' />
                        </Col>
                        <Divider className='queueActivityDivider' />
                        {Queue?.queue_status === 'cancelled_by_admin' ||
                        Queue?.queue_status === 'cancelled_by_user' ? (
                          <Col span={24}>
                            {Queue.queue_activities?.map((queue, i) => {
                              return queue?.activity_type ===
                                'cancelled_by_admin' ||
                                queue?.activity_type === 'cancelled_by_user' ? (
                                <>
                                  <Row key={i} className='queueActivitySpace'>
                                    <Col span={24} className='centerText'>
                                      <Typography className='queueActivityText'>
                                        Cancellation time
                                      </Typography>
                                    </Col>
                                    <Col span={24} className='centerText'>
                                      <Typography className='queueActivitydetails'>{`${moment
                                        .utc(queue?.created_at)
                                        .tz(organizationTimezone)
                                        .format('hh:mm A')} | ${moment(
                                        queue?.created_at
                                      ).format('DD/MM/YYYY')}`}</Typography>
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col span={12}>
                                      <Row>
                                        <Col span={24} className='centerText'>
                                          <Typography className='queueActivityText'>
                                            {Queue?.queue_type === 'reservation'
                                              ? 'Booking time'
                                              : 'Arrival time'}
                                          </Typography>
                                        </Col>
                                        <Col span={24} className='centerText'>
                                          <Typography className='queueActivitydetails'>
                                            {moment
                                              .utc(Queue?.created_at)
                                              .tz(organizationTimezone)
                                              .format('hh:mm A')}{' '}
                                            |{' '}
                                            {moment(Queue?.created_at).format(
                                              'DD/MM/YYYY'
                                            )}
                                          </Typography>
                                        </Col>
                                      </Row>
                                    </Col>
                                    <Col span={12}>
                                      <Row>
                                        <Col span={24} className='centerText'>
                                          <Typography className='queueActivityText'>
                                            {Queue?.booking_type ===
                                            'reservation'
                                              ? 'Reservation time'
                                              : 'Estimated wait time'}
                                          </Typography>
                                        </Col>
                                        <Col span={24} className='centerText'>
                                          <Typography className='queueActivitydetails'>
                                            {Queue?.booking_type ===
                                            'reservation' ? (
                                              `${moment(
                                                Queue?.reservation_time,
                                                'HH:mm'
                                              ).format('hh:mm A')} | ${
                                                Queue?.reservation_date
                                              }`
                                            ) : (
                                              <div>
                                                {moment
                                                  .tz(
                                                    Queue?.created_at,
                                                    'hh:mm A',
                                                    organizationTimezone
                                                  )
                                                  .diff(
                                                    moment.tz(
                                                      queue?.created_at,
                                                      'hh:mm A',
                                                      organizationTimezone
                                                    ),

                                                    'minutes'
                                                  )}{' '}
                                                mins (
                                                {
                                                  <span
                                                    style={{
                                                      color: '#7b7b7b',
                                                    }}
                                                  >
                                                    {moment
                                                      .tz(
                                                        Queue?.reservation_time,
                                                        'HH:mm',
                                                        organizationTimezone
                                                      )
                                                      .format('hh:mm A')}
                                                  </span>
                                                }
                                                )
                                              </div>
                                            )}
                                          </Typography>
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </>
                              ) : (
                                ''
                              );
                            })}
                          </Col>
                        ) : Queue.queue_status === 'completed' ? (
                          <>
                            <Col span={24}>
                              <Row style={{ paddingTop: '20px' }}>
                                {Queue.queue_activities?.map((queue, i) => {
                                  const isLastItem =
                                    queue?.activity_type === 'arrived' ||
                                    queue?.activity_type === 'completed'
                                      ? i === Queue.queue_activities?.length - 1
                                      : false;
                                  const queueTimeHeading =
                                    queue?.activity_type === 'arrived'
                                      ? 'Arrival time'
                                      : queue?.activity_type === 'completed'
                                      ? 'Completed time'
                                      : '';

                                  const queueTime = `${moment(
                                    queue?.created_at
                                  ).format('hh:mm A')} | ${moment(
                                    queue?.created_at
                                  ).format('DD/MM/YYYY')}`;

                                  return (
                                    (queue?.activity_type === 'arrived' ||
                                      queue?.activity_type === 'completed') && (
                                      <Col span={24} className='centerText'>
                                        <Timeline className='customTimeline'>
                                          <Timeline.Item
                                            dot={
                                              <div
                                                style={{
                                                  width: '10px',
                                                  height: '10px',
                                                  borderRadius: '50%',
                                                  backgroundColor: '#262626',
                                                }}
                                              ></div>
                                            }
                                            className={`${
                                              isLastItem
                                                ? 'hideTail last-timeline-item'
                                                : ''
                                            }`}
                                          >
                                            <Typography className='queueActivitydetails'>
                                              {queueTimeHeading}
                                            </Typography>
                                            <Typography className='queueActivitydetails'>
                                              {queueTime}
                                            </Typography>
                                          </Timeline.Item>
                                        </Timeline>
                                      </Col>
                                    )
                                  );
                                })}
                              </Row>
                              <Divider className='queueActivityDivider' />
                              <Row>
                                <Col span={12}>
                                  <Row>
                                    <Col span={24} className='centerText'>
                                      <Typography className='queueActivityText'>
                                        {Queue.booking_type === 'reservation'
                                          ? 'Created time'
                                          : 'Total wait time'}
                                      </Typography>
                                    </Col>
                                    <Col span={24} className='centerText'>
                                      <Typography className='queueActivitydetails'>
                                        {Queue.booking_type ===
                                        'reservation' ? (
                                          `${Queue.booking_time} | ${Queue.booking_date}`
                                        ) : Queue.queue_activities?.length >
                                          1 ? (
                                          <div
                                            style={{
                                              color: '#eb5757',
                                            }}
                                          >
                                            {moment(
                                              Queue?.queue_activities[
                                                Queue?.queue_activities.length -
                                                  1
                                              ]?.created_at
                                            ).diff(
                                              moment(
                                                Queue?.queue_activities[0]
                                                  ?.created_at
                                              ),
                                              'minutes'
                                            )}{' '}
                                            mins
                                          </div>
                                        ) : null}
                                      </Typography>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col span={12}>
                                  <Row>
                                    <Col span={24} className='centerText'>
                                      <Typography className='queueActivityText'>
                                        {Queue.booking_type === 'reservation'
                                          ? 'Reservation time'
                                          : 'Estimated wait time'}
                                      </Typography>
                                    </Col>
                                    <Col span={24} className='centerText'>
                                      <Typography className='queueActivitydetails'>
                                        {Queue.booking_type ===
                                        'reservation' ? (
                                          `${moment(
                                            Queue?.reservation_time,
                                            'HH:mm'
                                          ).format('hh:mm A')} | ${
                                            Queue.reservation_date
                                          }`
                                        ) : (
                                          <div>
                                            {moment(
                                              Queue?.reservation_time,
                                              'HH:mm'
                                            ).diff(
                                              moment(
                                                moment(Queue?.created_at)
                                                  .tz(organizationTimezone)
                                                  .format('hh:mm A'),
                                                'hh:mm A'
                                              ),
                                              'minutes'
                                            )}{' '}
                                            mins{' '}
                                            {
                                              <span
                                                style={{ color: '#7b7b7b' }}
                                              >
                                                (
                                                {moment
                                                  .tz(
                                                    Queue?.reservation_time,
                                                    'HH:mm',
                                                    organizationTimezone
                                                  )
                                                  .format('hh:mm A')}
                                                )
                                              </span>
                                            }
                                          </div>
                                        )}
                                      </Typography>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Col>
                          </>
                        ) : (
                          ''
                        )}
                      </Row>
                    </Col>
                  </Row>
                )}{' '}
                {tabKey !== '3' && (
                  <Row
                    style={{ marginTop: '15px' }}
                    className='queueActivityContainer'
                  >
                    <Col span={24}>
                      <Row>
                        <Col span={24} className='centerText'>
                          <IoMdPerson fontSize='large' />
                        </Col>
                        <Divider className='queueActivityDivider' />
                        <Col span={24}>
                          <Row className='queueActivitySpace'>
                            <Col span={24} className='centerText'>
                              <Typography className='queueActivityText'>
                                Customer name
                              </Typography>
                            </Col>
                            <Col span={24} className='centerText'>
                              <Typography className='queueActivitydetails'>
                                {Queue?.user?.first_name || '-'}{' '}
                                {Queue?.user?.last_name || '-'}
                              </Typography>
                            </Col>
                          </Row>

                          <Row className='queueActivitySpace'>
                            <Col span={12}>
                              <Row>
                                <Col span={24} className='centerText'>
                                  <Typography className='queueActivityText'>
                                    Phone no
                                  </Typography>
                                </Col>
                                <Col span={24} className='centerText'>
                                  <Typography className='queueActivitydetails'>
                                    {Queue?.user?.country_code}{' '}
                                    {Queue?.user?.phone}
                                  </Typography>
                                </Col>
                              </Row>
                            </Col>
                            <Col span={12}>
                              <Row>
                                <Col span={24} className='centerText'>
                                  <Typography className='queueActivityText'>
                                    Party size
                                  </Typography>
                                </Col>
                                <Col span={24} className='centerText'>
                                  <Typography className='queueActivitydetails'>
                                    {Queue.total_guests || '-'}
                                  </Typography>
                                </Col>
                              </Row>
                            </Col>
                          </Row>

                          <Row className='queueActivitySpace'>
                            <Col span={12}>
                              <Row>
                                <Col span={24} className='centerText'>
                                  <Typography className='queueActivityText'>
                                    Booking type
                                  </Typography>
                                </Col>
                                <Col span={24} className='centerText'>
                                  <Typography className='queueActivitydetails'>
                                    {_.capitalize(Queue.queue_type)}
                                  </Typography>
                                </Col>
                              </Row>
                            </Col>
                            <Col span={12} className='centerText'>
                              <Row>
                                <Col span={24} className='centerText'>
                                  <Typography className='queueActivityText'>
                                    Booking no
                                  </Typography>
                                </Col>
                                <Col span={24} className='centerText'>
                                  <Typography className='queueActivitydetails'>
                                    {_.split(Queue?.queue_number, '.')[1]}
                                  </Typography>
                                </Col>
                              </Row>
                            </Col>
                          </Row>

                          {Queue?.queue_status === 'cancelled_by_admin' ||
                          Queue?.queue_status === 'cancelled_by_user' ? (
                            <Row>
                              <Col span={24} className='centerText'>
                                <Typography className='queueActivityText'>
                                  Cancellation reason
                                </Typography>
                              </Col>
                              <Col span={24} className='centerText'>
                                <Typography className='queueActivitydetails'>
                                  {Queue.reason || '-'}
                                </Typography>
                              </Col>
                            </Row>
                          ) : (
                            ''
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )}
                <Row
                  style={{ marginTop: '15px' }}
                  className='queueActivityContainer'
                >
                  <Col span={24}>
                    <Row>
                      <Col span={24} className='centerText'>
                        <MdTableRestaurant fontSize='large' />
                      </Col>

                      <Divider className='queueActivityDivider' />

                      {Queue?.queue_status === 'cancelled_by_admin' ||
                      Queue?.queue_status === 'cancelled_by_user' ? (
                        <Col span={24}>
                          <Row className='queueActivitySpace'>
                            <Col span={24} className='centerText'>
                              <Typography className='queueActivityText'>
                                Layout Preferrence
                              </Typography>
                            </Col>
                            <Col span={24} className='centerText'>
                              <Typography className='queueActivitydetails'>
                                {Queue.organization_layout?.layout_name || '-'}
                              </Typography>
                            </Col>
                          </Row>
                          <Row>
                            <Col span={24} className='centerText'>
                              <Typography className='queueActivityText'>
                                Extras
                              </Typography>
                            </Col>

                            <Col span={24} className='centerText'>
                              {Queue.organization_table ? (
                                <div style={{ display: 'flex', gap: '10px' }}>
                                  {Queue?.organization_table
                                    ?.kids_seat_availability && (
                                    <img src={kidsChair} alt='kidsChair' />
                                  )}
                                  {Queue.organization_table
                                    .barbeque_grill_availability && (
                                    <img src={bbqGrill} alt='props' />
                                  )}
                                  {Queue.organization_table
                                    .disabled_seat_availability && (
                                    <img src={disabledSeat} alt='props' />
                                  )}
                                </div>
                              ) : (
                                '-'
                              )}
                            </Col>
                          </Row>
                        </Col>
                      ) : Queue.queue_status === 'completed' ||
                        tabKey === '3' ? (
                        <Col span={24}>
                          {tabKey !== '3' && (
                            <Row className='queueActivitySpace'>
                              <Col span={24} className='centerText'>
                                <Typography className='queueActivityText'>
                                  Average waiting time
                                </Typography>
                              </Col>
                              <Col span={24} className='centerText'>
                                <Typography className='queueActivitydetails'>
                                  {Queue?.organization_table
                                    ?.avg_waiting_time || '-'}
                                </Typography>
                              </Col>
                            </Row>
                          )}

                          <Row className='queueActivitySpace'>
                            <Col span={12}>
                              <Row>
                                <Col span={24} className='centerText'>
                                  <Typography className='queueActivityText'>
                                    Layout
                                  </Typography>
                                </Col>
                                <Col span={24} className='centerText'>
                                  <Typography className='queueActivitydetails'>
                                    {tabKey === '3'
                                      ? CaptainTable?.organization_layout
                                          ?.layout_name || '-'
                                      : Queue?.organization_layout
                                          ?.layout_name || '-'}
                                  </Typography>
                                </Col>
                              </Row>
                            </Col>
                            <Col span={12}>
                              <Row>
                                <Col span={24} className='centerText'>
                                  <Typography className='queueActivityText'>
                                    Table no
                                  </Typography>
                                </Col>
                                <Col span={24} className='centerText'>
                                  <Typography className='queueActivitydetails'>
                                    {tabKey === '3'
                                      ? CaptainTable?.table_number || '-'
                                      : Queue.organization_table
                                          ?.table_number || '-'}
                                  </Typography>
                                </Col>
                              </Row>
                            </Col>
                          </Row>

                          <Row className='queueActivitySpace'>
                            <Col span={12}>
                              <Row>
                                <Col span={24} className='centerText'>
                                  <Typography className='queueActivityText'>
                                    Capacity
                                  </Typography>
                                </Col>
                                <Col span={24} className='centerText'>
                                  <Typography className='queueActivitydetails'>
                                    {tabKey === '3'
                                      ? CaptainTable?.seating_capacity || '-'
                                      : Queue.organization_table
                                          ?.seating_capacity || '-'}
                                  </Typography>
                                </Col>
                              </Row>
                            </Col>
                            <Col span={12}>
                              <Row>
                                <Col span={24} className='centerText'>
                                  <Typography className='queueActivityText'>
                                    Status
                                  </Typography>
                                </Col>
                                <Col span={24} className='centerText'>
                                  <Typography className='queueActivitydetails'>
                                    {tabKey === '3'
                                      ? CaptainTable?.is_block
                                        ? 'Blocked'
                                        : CaptainTable?.orders &&
                                          CaptainTable?.orders?.length > 0
                                        ? 'Busy'
                                        : 'Available'
                                      : Queue?.organization_table?.is_block
                                      ? 'Blocked'
                                      : Queue?.organization_table
                                          ?.current_order_id
                                      ? 'Busy'
                                      : 'Available'}
                                  </Typography>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          <Row>
                            <Col span={24} className='centerText'>
                              <Typography className='queueActivityText'>
                                Extras
                              </Typography>
                            </Col>
                            <Col span={24} className='centerText'>
                              {tabKey === '3' ? (
                                <div style={{ display: 'flex', gap: '10px' }}>
                                  {CaptainTable?.kids_seat_availability ||
                                  CaptainTable?.barbeque_grill_availability ||
                                  CaptainTable?.disabled_seat_availability ? (
                                    <>
                                      {CaptainTable.kids_seat_availability && (
                                        <img src={kidsChair} alt='kidsChair' />
                                      )}
                                      {CaptainTable.barbeque_grill_availability && (
                                        <img src={bbqGrill} alt='bbqGrill' />
                                      )}
                                      {CaptainTable.disabled_seat_availability && (
                                        <img
                                          src={disabledSeat}
                                          alt='disabledSeat'
                                        />
                                      )}
                                    </>
                                  ) : (
                                    '-'
                                  )}
                                </div>
                              ) : Queue.organization_table ? (
                                <div style={{ display: 'flex', gap: '10px' }}>
                                  {Queue?.organization_table
                                    ?.kids_seat_availability && (
                                    <img src={kidsChair} alt='kidsChair' />
                                  )}
                                  {Queue.organization_table
                                    .barbeque_grill_availability && (
                                    <img src={bbqGrill} alt='props' />
                                  )}
                                  {Queue.organization_table
                                    .disabled_seat_availability && (
                                    <img src={disabledSeat} alt='props' />
                                  )}
                                </div>
                              ) : (
                                '-'
                              )}
                            </Col>
                          </Row>
                        </Col>
                      ) : (
                        ''
                      )}
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Spin>
    </Drawer>
  );
};
export default QueueDetails;
