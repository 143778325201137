import API from '../Utils/API';

export const getCategory = (url, successCallback) => (dispatch) => {
  dispatch({ type: 'GET_CATEGORY_REQUEST' });
  API.get(url)
    .then((response) => {
      dispatch({ type: 'GET_CATEGORY_SUCCESS', payload: response.data });
      successCallback && successCallback(response.data);
    })
    .catch((error) => {
      try {
        dispatch({
          type: 'GET_CATEGORY_FAILURE',
          payload: error.response.data,
        });
      } catch {}
    });
};
export const createCategory =
  (data, successCreateCategory, failureCreateCategory) => (dispatch) => {
    dispatch({ type: 'CREATE_CATEGORY_REQUEST' });
    API.post('api/categories', data)
      .then((response) => {
        dispatch({ type: 'CREATE_CATEGORY_SUCCESS', payload: response.data });
        successCreateCategory && successCreateCategory();
      })
      .catch((error) => {
        dispatch({
          type: 'CREATE_CATEGORY_FAILURE',
          payload: error.response.data,
        });
        failureCreateCategory && failureCreateCategory();
      });
  };
export const deleteCategory =
  (CategoryId, successDeleteCallback) => (dispatch) => {
    dispatch({ type: 'DELETE_CATEGORY_REQUEST' });
    API.delete(`api/categories/${CategoryId}`)
      .then((response) => {
        dispatch({ type: 'DELETE_CATEGORY_SUCCESS', payload: response.data });
        successDeleteCallback();
      })
      .catch((error) => {
        dispatch({
          type: 'DELETE_CATEGORY_FAILURE',
          payload: error.response.data,
        });
      });
  };

export const deleteMultiCategory =
  (data, successDeleteCallback) => (dispatch) => {
    dispatch({ type: 'DELETE_MULTI_CATEGORY_REQUEST' });
    API.delete(`api/multi_destroy_categories`, { data })
      .then((response) => {
        dispatch({
          type: 'DELETE_MULTI_CATEGORY_SUCCESS',
          payload: response.data,
        });
        successDeleteCallback && successDeleteCallback(response.data);
      })
      .catch((error) => {
        dispatch({
          type: 'DELETE_MULTI_CATEGORY_FAILURE',
          payload: error.response.data,
        });
      });
  };

export const updateCategory = (data, successUpdateCallBack) => (dispatch) => {
  dispatch({ type: 'UPDATE_CATEGORY_REQUEST' });
  API.put(`api/categories/${data.id}`, data)
    .then((response) => {
      dispatch({ type: 'UPDATE_CATEGORY_SUCCESS', payload: response.data });
      successUpdateCallBack && successUpdateCallBack();
    })
    .catch((error) => {
      dispatch({
        type: 'UPDATE_CATEGORY_FAILURE',
        payload: error.response.data,
      });
    });
};
export const getCategoryById = (Id) => (dispatch) => {
  dispatch({ type: 'GET_CATEGORY_BY_ID_REQUEST' });
  API.get(`api/categories/${Id}`)
    .then((response) => {
      dispatch({ type: 'GET_CATEGORY_BY_ID_SUCCESS', payload: response.data });
    })
    .catch((error) => {
      dispatch({
        type: 'GET_CATEGORY_BY_ID_FAILURE',
        payload: error?.response.data,
      });
    });
};
export const reOrderCategory = (data, successUpdateCallBack) => (dispatch) => {
  dispatch({ type: 'REORDER_CATEGORY_REQUEST' });
  API.put(`api/re_order_category/${data.id}`, data)
    .then((response) => {
      dispatch({ type: 'REORDER_CATEGORY_SUCCESS', payload: response.data });
      successUpdateCallBack && successUpdateCallBack(response.data);
    })
    .catch((error) => {
      dispatch({
        type: 'REORDER_CATEGORY_FAILURE',
        payload: error.response.data,
      });
    });
};
