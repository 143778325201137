export const KidSeatQueue = (props) => {
  const { color } = props;
  return (
    <svg
      width='28'
      height='32'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.8125 10.8125H5.1875C4.67187 10.8125 4.25 10.3906 4.25 9.875C4.25 9.35937 4.67187 8.9375 5.1875 8.9375H10.8125C11.3281 8.9375 11.75 9.35937 11.75 9.875C11.75 10.3906 11.3281 10.8125 10.8125 10.8125Z'
        stroke={color ? color : '#262626'}
        stroke-miterlimit='10'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M5.18667 10.8125L4.34292 12.6875C4.0148 13.3437 4.53042 14.0938 5.28042 14.0938C5.74917 14.0938 6.17105 13.7656 6.31167 13.2969L6.87417 10.8125'
        stroke={color ? color : '#262626'}
        stroke-miterlimit='10'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M10.8047 10.8125L11.6484 12.6875C11.9766 13.3437 11.4609 14.0938 10.7109 14.0938C10.2422 14.0938 9.82031 13.7656 9.67969 13.2969L9.11719 10.8125'
        stroke={color ? color : '#262626'}
        stroke-miterlimit='10'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M9.02957 2.375H6.92019C4.81082 2.375 3.49832 4.95312 4.62332 6.96875L5.70144 8.9375H10.2014L11.2796 6.96875C12.4514 4.95312 11.1389 2.375 9.02957 2.375Z'
        stroke={color ? color : '#262626'}
        stroke-miterlimit='10'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M8.9375 5.65625C8.42187 5.65625 8 5.23438 8 4.71875C8 4.34375 8.23438 4.01562 8.51563 3.875C8.375 3.82812 8.1875 3.78125 8 3.78125C7.20312 3.78125 6.59375 4.39062 6.59375 5.1875C6.59375 5.98438 7.20312 6.59375 8 6.59375C8.65625 6.59375 9.17187 6.17188 9.35937 5.5625C9.21875 5.60938 9.07813 5.65625 8.9375 5.65625Z'
        stroke={color ? color : '#262626'}
        stroke-miterlimit='10'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
};

export const DisabledChair = (props) => {
  const { color } = props;
  return (
    <svg
      width='26'
      height='26'
      viewBox='0 0 33 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M28.4993 24.7261L25.5108 26.5833L21.9247 19.1548H14.7523M14.7523 19.1548H13.5568L11.166 9.25H13.5568L13.8888 12.0007M14.7523 19.1548L13.8888 12.0007M13.8888 12.0007H19.166'
        stroke={color}
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M21.9487 24.9383C21.3099 24.2995 20.1983 24.5427 19.6424 25.2548C18.5025 26.7153 16.7256 27.6543 14.7295 27.6543C11.2894 27.6543 8.50065 24.8656 8.50065 21.4256C8.50065 19.8108 9.11517 18.3395 10.1231 17.2328C10.5106 16.8073 10.7065 16.2207 10.5411 15.6695C10.272 14.7725 9.21664 14.3817 8.5437 15.0329C6.87276 16.6501 5.83398 18.9165 5.83398 21.4256C5.83398 26.3384 9.8166 30.3209 14.7295 30.3209C17.8132 30.3209 20.5304 28.7519 22.1262 26.3684C22.4284 25.9172 22.3327 25.3223 21.9487 24.9383Z'
        fill={color}
      />
      <path
        d='M14.1673 4.00065C14.1673 4.92112 13.4211 5.66732 12.5007 5.66732C11.5802 5.66732 10.834 4.92112 10.834 4.00065C10.834 3.08017 11.5802 2.33398 12.5007 2.33398C13.4211 2.33398 14.1673 3.08017 14.1673 4.00065Z'
        fill={color}
        stroke={color}
        stroke-width='2'
      />
    </svg>
  );
};

export const BarbecueGrill = (props) => {
  const { color } = props;

  return (
    <svg
      width='26'
      height='26'
      viewBox='0 0 33 26'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M4.20929 11.3134C5.30122 13.5695 7.11202 15.4012 9.37702 16.6289L5.90158 24.7358C5.73198 25.1317 5.91508 25.5905 6.31148 25.7601C6.41138 25.8033 6.51595 25.8236 6.61841 25.8236C6.92118 25.8236 7.20936 25.6462 7.33627 25.3502L10.7915 17.2911C12.4858 17.9715 14.3711 18.3451 16.3598 18.3451C18.4016 18.3451 20.3352 17.9534 22.0639 17.2381L25.5425 25.3507C25.6694 25.6462 25.9577 25.8236 26.2604 25.8236C26.3628 25.8236 26.4669 25.8033 26.5673 25.7601C26.9637 25.5905 27.1468 25.1317 26.9772 24.7353L23.4705 16.5566C25.6845 15.3248 27.4537 13.5113 28.5233 11.2859C30.6114 10.8598 32.4597 9.92451 32.4987 8.81851C32.5419 7.58253 31.4843 6.89634 29.6459 6.60868C29.6496 6.48695 29.6558 6.36625 29.6558 6.24299C29.6558 6.14156 29.6423 6.04115 29.622 5.94125C29.7884 5.63278 29.881 5.30921 29.881 4.969C29.881 1.96841 23.0065 0.175781 16.3603 0.175781C9.7141 0.175781 2.83802 1.96995 2.83802 4.96996C2.83802 5.31017 2.93062 5.63425 3.09708 5.94272C3.07782 6.04262 3.0638 6.14246 3.0638 6.24389C3.0638 6.35729 3.0695 6.46865 3.07263 6.58097C1.24826 6.85097 0.457057 7.54233 0.501792 8.8194C0.541342 9.95043 2.21174 10.9019 4.20929 11.3134ZM3.61885 4.96893C3.62096 3.03167 8.7398 0.957062 16.3593 0.957062C23.9787 0.957062 29.0971 3.03115 29.0997 4.96836C29.0997 4.96887 29.0997 4.96938 29.0997 4.96989C29.0997 5.47189 28.7532 5.98323 28.1113 6.46551C28.0609 6.50346 28.0104 6.54148 27.9563 6.57892C26.042 7.90597 21.8501 8.9833 16.3593 8.9833C8.73826 8.9833 3.61892 6.90818 3.61892 4.97041C3.61834 4.96944 3.61885 4.96945 3.61885 4.96893ZM30.9006 8.99847C30.8747 9.49944 30.0912 9.83863 29.0315 10.0488C29.2718 9.34546 29.4441 8.60834 29.546 7.84261C30.5464 8.02936 30.935 8.34249 30.9006 8.99847ZM3.16991 7.81919C3.27134 8.59324 3.44459 9.33817 3.68701 10.0483C2.76365 9.83812 2.12534 9.49893 2.09936 8.99853C2.06346 8.31389 2.2159 8.00228 3.16991 7.81919Z'
        fill={color}
      />
      <path
        d='M16.5332 7.87775C20.4904 7.87775 27.9741 7.23688 27.9741 4.8101C27.9741 4.43501 27.7822 4.09115 27.4394 3.77903C25.8699 2.34901 21.0856 1.58789 16.5332 1.58789C12.4303 1.58789 8.1391 2.20643 6.18212 3.37534C5.49389 3.78684 5.0918 4.26542 5.0918 4.8101C5.09173 7.23688 12.5755 7.87775 16.5332 7.87775ZM24.5637 6.00243L24.6027 3.57098C26.0983 3.99394 26.9337 4.472 26.9337 4.81061C26.9337 5.13316 26.116 5.59823 24.5637 6.00243ZM12.1723 6.62718C11.3316 6.54084 10.5805 6.4316 9.90991 6.3093L9.95931 3.2172C10.6356 3.083 11.3852 2.96179 12.2149 2.86605L12.1723 6.62718ZM13.7777 2.72564C14.4908 2.67572 15.2447 2.64449 16.038 2.63514L15.9917 6.83063C15.1927 6.82179 14.4389 6.79472 13.7319 6.75152L13.7777 2.72564ZM19.3371 6.75152C18.7711 6.7864 18.1786 6.81187 17.5528 6.82486L17.5986 2.64346C18.2218 2.65959 18.8143 2.69133 19.3855 2.73293L19.3371 6.75152ZM20.9446 2.87808C21.713 2.96908 22.4126 3.08095 23.0473 3.2053L22.9974 6.33797C22.3685 6.44824 21.6714 6.54712 20.8994 6.62666L20.9446 2.87808ZM8.39247 3.58967L8.35451 5.96391C6.89691 5.56904 6.13169 5.12324 6.13169 4.81061C6.1322 4.47667 6.94004 4.00795 8.39247 3.58967Z'
        fill={color}
      />
    </svg>
  );
};
