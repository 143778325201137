import API from '../Utils/API';

export const getQueue = (params) => (dispatch) => {
  dispatch({ type: 'GET_QUEUE_REQUEST' });
  API.get(`api/organization_queues${params}`)
    .then((response) => {
      dispatch({ type: 'GET_QUEUE_SUCCESS', payload: response.data });
    })
    .catch((error) => {
      try {
        dispatch({
          type: 'GET_QUEUE_FAILURE',
          payload: error.response.data,
        });
      } catch {}
    });
};

export const getQueueById = (Id) => (dispatch) => {
  dispatch({ type: 'GET_QUEUE_BY_ID_REQUEST' });
  API.get(`api/organization_queues/${Id}`)
    .then((response) => {
      dispatch({
        type: 'GET_QUEUE_BY_ID_SUCCESS',
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: 'GET_QUEUE_BY_ID_FAILURE',
        payload: error?.response.data,
      });
    });
};
