import React, { useEffect, useState } from 'react';
import './Restaurant.css';
import {
  Button,
  Row,
  Col,
  Typography,
  Spin,
  Input,
  message,
  Upload,
  Select,
  Tabs,
  Card,
  Checkbox,
  Image,
} from 'antd';
import { getAllState, getStateById } from '../../Actions/StateAction';
import { getTimeZone } from '../../Actions/TimezoneAction';
import {
  getRestaurant,
  createRestaurantAction,
  updateRestaurantAction,
  getRestaurantById,
  getRestaurantDetails,
} from '../../Actions/RestaurantAction';
import { getCountryById, getAllCountry } from '../../Actions/countryAction';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import { falseyValueCases, ImageEnv } from '../CommonLogics/CommonMethods';
import { createrestaurantSchema, editSchema } from '../../Utils/Schema';
import PhoneInput from 'react-phone-input-2';
import logo from '../../assests/logo.svg';
import Location from './Location';
import OprationTiming from './OprationTiming';
import FSSAIDetails from './FSSAIDetails';
import { LuAsterisk } from 'react-icons/lu';
import { getUsers } from '../../Actions/authenticationAction';
import { useNavigate, useParams } from 'react-router-dom';
import { deleteImage } from '../../Actions/ImageAction';
import { MdDeleteOutline } from 'react-icons/md';

const RestarantModalForm = (props) => {
  const {
    editId,
    setEditId,
    setIsModalVisible,
    setIsDefaultImageVisible,
    showUploadImage,
    setShowUploadImage,
    isDefaultImageVisible,
    currentPage,
    setCurrentPage,
    setTabkey,
    isType,
    setIsType,
  } = props;
  const { TabPane } = Tabs;
  const dispatch = useDispatch();
  const [newFiles, setNewFiles] = useState();
  const IsEmpty = _.isEmpty(newFiles) ? true : false;
  const [preloadedRestaurantValue, setPreloadedRestaurantValue] = useState('');

  const [countryCode, setCountryCode] = useState('');
  const [number, setNumber] = useState('');
  const [displayNumber, setDisplayNumber] = useState('');
  const { Option } = Select;
  const [fileList, setFileList] = useState([]);
  const navigate = useNavigate();

  // const phoneNumberMethod = (value, country) => {
  //   setCountryCode(country.dialCode);
  //   setDisplayNumber(value);
  //   setNumber(value.slice(country.dialCode.length));
  // };

  const handleChange = (e) => {
    e.target.value = setNumber(e.target.value.replace(/\D/g, ''));
  };

  useEffect(() => {
    if (!editId) {
      dispatch(getAllCountry(`api/countries`));
    }
  }, []);
  const {
    allRestaurant,
    isCreateRestaurantLoading,
    isUpdateRestaurantLoading,
    isRestaurantloading,
    allCountries,
    allState,
    allTimeZone,
    restaurant,
    setCountryId,
    country,
    restaurantdeatils,
    State,
    TimeZone,
    allStates,
    setRestaurantId,
  } = useSelector((state) => {
    const {
      allRestaurant,
      isCreateRestaurantLoading,
      isUpdateRestaurantLoading,
      isRestaurantloading,
      restaurant,
      restaurantdeatils,
      setRestaurantId,
    } = state.restaurant;
    const { allCountries, setCountryId, country } = state.country;
    const { allState, State, allStates } = state.states;
    const { setCode, allTimeZone, TimeZone } = state.timeZones;
    return {
      State,
      restaurant,
      allTimeZone,
      setCode,
      allState,
      setCountryId,
      allCountries,
      allRestaurant,
      isCreateRestaurantLoading,
      isUpdateRestaurantLoading,
      isRestaurantloading,
      country,
      restaurantdeatils,
      TimeZone,
      allStates,
      setRestaurantId,
    };
  });
  const [countryId, setcountryId] = useState(country?.name);
  const [stateId, setstateId] = useState(State?.name);
  const [timeZoneId, settimeZoneId] = useState(allTimeZone);

  const { organizations } = allRestaurant;
  const { orgId } = useParams();

  const the_restaurant = _.filter(organizations, function (o) {
    return o.id === editId;
  });

  if (editId && the_restaurant.length > 0) {
    the_restaurant[0].country_id = country?.name;
    the_restaurant[0].state_id = State?.name;
    the_restaurant[0].time_zone = allTimeZone;
  }
  const restaurantData = the_restaurant.length > 0 ? the_restaurant[0] : {};
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    clearErrors,
  } = useForm({
    defaultValues: {
      name: editId ? restaurant.name || '' : '',
      legal_entity_name: editId ? restaurant.legal_entity_name || '' : '',
    },
    resolver: yupResolver(editId ? editSchema : createrestaurantSchema),
  });

  useEffect(() => {
    if (editId && restaurant) {
      reset({
        name: restaurant.name || '',
        legal_entity_name: restaurant.legal_entity_name || '',
      });
    }
  }, [editId, restaurant, reset]);

  const [checkedValueDineIn, setCheckedValueDineIn] = useState(
    editId && restaurantData.is_dine_in ? true : false
  );
  const [checkedValueTakeaway, setCheckedValueTakeaway] = useState(
    editId && restaurantData?.is_take_away ? true : false
  );
  const [checkedValueIsDelivery, setCheckedValueIsDelivery] = useState(
    editId && restaurantData?.is_delivery ? true : false
  );

  const onChangeDineIn = (e) => {
    setCheckedValueDineIn(e);
  };
  const onChangeTakeaway = (e) => {
    setCheckedValueTakeaway(e);
  };
  const onChangeIsDelivery = (e) => {
    setCheckedValueIsDelivery(e);
  };

  const handleCancel = () => {
    reset(preloadedRestaurantValue);
    setEditId('');
    setValue('name', '');
    setValue('legal_entity_name', '');
    setValue('published', '');
    setValue('org_logo', '');
    setValue('country_id', '');
    setValue('state_id', '');
    setValue('time_zone', '');
    setValue('email', '');
    setValue('country_code', '');
    setNumber('');
    setValue('phone', '');
    setValue('is_cafe', '');
    setNewFiles([]);
    setFileList([]);
    setShowUploadImage(false);
    setIsDefaultImageVisible(true);
    clearErrors();
  };
  const handleeditCancel = () => {
    reset(preloadedRestaurantValue);
    setNewFiles([]);
    setFileList([]);
    setShowUploadImage(false);
    setIsDefaultImageVisible(true);
    clearErrors();
  };
  const handleDeleteImage = (key) => {
    dispatch(deleteImage(key, successCallbackImageDelete));
  };
  const successCallbackImageDelete = () => {
    setCurrentPage(1);
    setIsType('');
    setIsModalVisible(false);
    dispatch(getRestaurant(`?page=${currentPage}`));
  };
  const selectCountry = (e) => {
    const countryObj = _.filter(allCountries?.countries, function (o) {
      return o.id === e;
    });
    setcountryId(countryObj[0]?.name);
    dispatch(getCountryById(countryObj[0]?.id));
    dispatch(getAllState(`api/states?country_id=${countryObj[0]?.id}`));
    dispatch(getTimeZone(`api/time_zones?code=${countryObj[0]?.code}`));
  };
  const selectState = (e) => {
    const countryObj = _.filter(allStates, function (o) {
      return o.id === e;
    });
    setstateId(countryObj[0]?.name);
    dispatch(getStateById(countryObj[0]?.id));
  };
  const selectTimezone = (e) => {
    settimeZoneId(allTimeZone[0]);
  };
  useEffect(() => {
    if (editId) {
      dispatch(getRestaurantDetails());
    }
  }, [dispatch]);
  const handleCreateRestaurant = (data) => {
    if (!falseyValueCases(newFiles)) {
      data.org_logo = newFiles;
    }
    data.is_dine_in = checkedValueDineIn;
    data.is_take_away = checkedValueTakeaway;
    data.is_delivery = checkedValueIsDelivery;
    data.time_zone = timeZoneId;
    data.phone = number;
    dispatch(
      createRestaurantAction(
        data,
        successCreateRestaurant,
        failureCreateRestaurant,
        IsEmpty
      )
    );
  };

  const successCreateRestaurant = (data) => {
    setCurrentPage(1);
    setIsType('');
    setIsModalVisible(false);
    dispatch(getRestaurant(`?page=${currentPage}`));
    localStorage.setItem('organization_id', data.id);
  };

  const failureCreateRestaurant = (failureResponse) => {
    setTimeout(() => {
      message.error(
        failureResponse
          ? failureResponse?.error || failureResponse?.message
          : 'Something went wrong.'
      );
    }, 1000);
  };
  const handleEditRestaurantMethod = (data) => {
    data.id = editId;
    data.is_dine_in = checkedValueDineIn;
    data.is_take_away = checkedValueTakeaway;
    data.is_delivery = checkedValueIsDelivery;
    if (!falseyValueCases(newFiles)) {
      data.org_logo = newFiles;
    }
    dispatch(
      updateRestaurantAction(data, UpdateCallBack, faliureUpdate, IsEmpty)
    );
  };

  const UpdateCallBack = (data) => {
    // dispatch(getRestaurant(`?page=${currentPage}`));
    dispatch(getRestaurantById(editId));
    dispatch(getRestaurantDetails());
    setTimeout(() => {
      message.success('Your Restaurant Updated Successfully');
    }, 1000);
  };
  const faliureUpdate = (failureResponse) => {
    setTimeout(() => {
      message.error(failureResponse);
    }, 1000);
  };

  const propsForUpload = {
    action: handleCreateRestaurant || handleEditRestaurantMethod,
    onRemove: (file) => {},
    beforeUpload: (file) => {
      setNewFiles(file);
      return false;
    },
    multiple: false,
  };

  const handleUploadMethod = (e) => {
    setShowUploadImage(true);
    setIsDefaultImageVisible(false);
    setFileList(e.fileList.slice(-1));
  };
  const inputStyles = {
    WebkitBoxShadow: '0 0 0 30px #f6f6f6 inset',
  };
  const isLoading = isCreateRestaurantLoading || isUpdateRestaurantLoading;
  return (
    <Card
      style={{
        backgroundColor: '#f6f6f6',
        border: 'rgb(229, 229, 229,1)',
      }}
      // scrollable={{ x: 1000, y:500 }}
    >
      <form
        onSubmit={handleSubmit(
          editId ? handleEditRestaurantMethod : handleCreateRestaurant
        )}
        // style={{ height: '100%' }}
      >
        <Spin spinning={isLoading}>
          <Row gutter={[16, 16]} className='button'>
            <Col xl={24} lg={24} md={24} sm={24} xs={24} className='formRows'>
              <Typography className='formHeading'>Organization Logo</Typography>
              <Col></Col>
              <Row>
                <Col span={24}>
                  <Row>
                    <Col span={8}>
                      <Upload
                        listType='picture-card'
                        {...propsForUpload}
                        maxCount={1}
                        className='formHeading'
                        accept='image/png,image/jpeg'
                      >
                        <Row>
                          <Col span={24}>
                            <img src={logo}></img>
                          </Col>
                        </Row>
                      </Upload>
                    </Col>
                    <Col style={{ marginLeft: '-110px' }}>
                      {newFiles
                        ? ''
                        : editId &&
                          restaurantData?.logo_url && (
                            <Col>
                              <Image
                                src={restaurantData?.logo_url}
                                width='100px'
                                height='103px'
                              />
                              <MdDeleteOutline
                                onClick={() =>
                                  handleDeleteImage(restaurantData?.org_logo)
                                }
                                style={{
                                  width: '20px',
                                  height: '20px',
                                  position: 'absolute',
                                  top: '83%',
                                  cursor: 'pointer',
                                }}
                              />
                            </Col>
                          )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={[16, 16]} className='button'>
            <Col xl={16} lg={16} md={16} sm={24} xs={24} className='formRows'>
              <Typography className='formHeading'>
                Organization Name <LuAsterisk className='asterisk' />
              </Typography>
              <Controller
                as={<Input style={inputStyles} />}
                name='name'
                control={control}
                className='inputLabel'
              />
              {errors.name && (
                <p style={{ color: 'red' }}>{errors.name.message}</p>
              )}
            </Col>
          </Row>
          <Row gutter={[16, 16]} className='button'>
            <Col xl={16} lg={16} md={16} sm={24} xs={24} className='formRows'>
              <Typography className='formHeading'>
                Legal Entity Name <LuAsterisk className='asterisk' />
              </Typography>
              <Controller
                as={<Input style={inputStyles} />}
                name='legal_entity_name'
                control={control}
                className='inputLabel'
              />
              {errors.legal_entity_name && (
                <p style={{ color: 'red' }}>
                  {errors.legal_entity_name.message}
                </p>
              )}
            </Col>
          </Row>
          {editId && isType === 'updateForm' ? (
            ''
          ) : (
            <>
              <Row gutter={[16, 16]} className='button'>
                <Col
                  xl={16}
                  lg={16}
                  md={16}
                  sm={24}
                  xs={24}
                  className='formRows'
                >
                  <Typography className='formHeading'>Mobile Number</Typography>
                  <Controller
                    render={() => (
                      <Input
                        className='inputLabel'
                        maxLength={10}
                        onChange={handleChange}
                        value={number}
                      />
                    )}
                    name='phone'
                    control={control}
                  />
                </Col>
              </Row>
              <Row gutter={[16, 16]} className='button'>
                <Col
                  xl={16}
                  lg={16}
                  md={16}
                  sm={24}
                  xs={24}
                  className='formRows'
                >
                  <Typography className='formHeading'>Email</Typography>
                  <Controller
                    as={<Input style={inputStyles} />}
                    name='email'
                    control={control}
                    defaultValue={editId ? restaurantdeatils?.email : ''}
                    className='inputLabel'
                  />
                  {errors.email && (
                    <p style={{ color: 'red' }}>{errors.email.message}</p>
                  )}
                </Col>
              </Row>
              <Row gutter={[16, 16]} className='button'>
                <Col xl={8} lg={8} md={8} sm={24} xs={24} className='formRows'>
                  <Typography className='formHeading'>
                    Country <LuAsterisk className='asterisk' />
                  </Typography>
                  <Controller
                    name='country_id'
                    as={
                      <Select
                        placeholder='select country'
                        style={{ width: '100%', borderRadius: '4px' }}
                        showSearch={true}
                        className='selection'
                        options={allCountries?.countries?.map((val, i) => {
                          return {
                            label: val.name,
                            value: val.id,
                          };
                        })}
                        filterOption={(input, option) =>
                          option.label
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        onSelect={selectCountry}
                      ></Select>
                    }
                    defaultValue={editId ? restaurantdeatils?.country : ''}
                    control={control}
                  />
                  {errors.country_id && (
                    <p style={{ color: 'red' }}>{errors.country_id.message}</p>
                  )}
                </Col>
                <Col xl={8} lg={8} md={8} sm={24} xs={24} className='formRows'>
                  <Typography className='formHeading'>
                    State <LuAsterisk className='asterisk' />
                  </Typography>
                  <Controller
                    name='state_id'
                    as={
                      <Select
                        placeholder='Select State'
                        style={{ width: '100%', borderRadius: '4px' }}
                        className='selection'
                        options={allStates?.map((val, i) => {
                          return {
                            label: val.name,
                            value: val.id,
                          };
                        })}
                        filterOption={(input, option) =>
                          option.label
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        onSelect={selectState}
                      >
                        {' '}
                      </Select>
                    }
                    defaultValue={editId ? restaurantdeatils?.state : ''}
                    control={control}
                  />
                  {errors.state_id && (
                    <p style={{ color: 'red' }}>{errors.state_id.message}</p>
                  )}
                </Col>
                <Col
                  xl={8}
                  lg={8}
                  md={8}
                  sm={24}
                  xs={24}
                  className='formRows'
                ></Col>
              </Row>
              <Row gutter={[16, 16]} className='button'>
                <Col
                  xl={16}
                  lg={16}
                  md={16}
                  sm={24}
                  xs={24}
                  className='formRows'
                >
                  <Typography className='formHeading'>
                    Time Zone <LuAsterisk className='asterisk' />
                  </Typography>
                  <Controller
                    name='time_zone'
                    as={
                      <Select
                        placeholder='Select Time Zone'
                        style={{ width: '100%', borderRadius: '4px' }}
                        className='selection'
                        onSelect={selectTimezone}
                      >
                        {allTimeZone?.map((val, i) => {
                          return (
                            <Option value={val.code} key={i}>
                              {allTimeZone}
                            </Option>
                          );
                        })}
                      </Select>
                    }
                    defaultValue={
                      editId ? restaurantdeatils?.organization_time_zone : ''
                    }
                    control={control}
                  />
                  {errors.time_zone && (
                    <p style={{ color: 'red' }}>{errors.time_zone.message}</p>
                  )}
                </Col>
              </Row>
              <Row gutter={[16, 16]} className='button'>
                <Col
                  xl={16}
                  lg={16}
                  md={16}
                  sm={24}
                  xs={24}
                  className='formRows'
                >
                  {/* <Typography className='formHeading'>
                    Organization Capabilities
                  </Typography>
                  <Card className='card'>
                    <Row gutter={[18, 18]} justify='space-around'>
                      <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                        <Row gutter={[8, 8]}>
                          <Col span={4}>
                            <Controller
                              name='is_dine_in'
                              control={control}
                              defaultValue={false}
                              render={(props) => (
                                <Checkbox
                                  {...props}
                                  checked={checkedValueDineIn}
                                  onChange={(e) =>
                                    onChangeDineIn(e.target.checked)
                                  }
                                />
                              )}

                            />
                          )}
                        />
                      </Col>

                      <Col span={20}>
                        <Typography className='formHeading'>Dine In</Typography>
                      </Col>
                    </Row>
                  </Col>

                  <Col xl={8} lg={6} md={6} sm={12} xs={12}>
                    <Row gutter={[8, 8]}>
                      <Col span={4}>
                        <Controller
                          name='is_take_away'
                          control={control}
                          defaultValue={false}
                          render={(props) => (
                            <Checkbox
                              {...props}
                              checked={checkedValueTakeaway}
                              onChange={(e) =>
                                onChangeTakeaway(e.target.checked)
                              }
                            />
                          )}
                        />
                      </Col>

                      <Col span={20}>
                        <Typography className='formHeading'>
                          Takeaway
                        </Typography>
                      </Col>
                    </Row>
                  </Col>

                  <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                    <Row gutter={[8, 8]}>
                      <Col span={4}>
                        <Controller
                          name='is_delivery'
                          control={control}
                          defaultValue={false}
                          render={(props) => (
                            <Checkbox
                              {...props}
                              checked={checkedValueIsDelivery}
                              onChange={(e) =>
                                onChangeIsDelivery(e.target.checked)
                              }
                            />
                          )}
                        />
                      </Col>

                      <Col span={20}>
                        <Typography className='formHeading'>
                          Delivery
                        </Typography>
                      </Col>
                    </Row>

                  </Card> */}
                </Col>
              </Row>
            </>
          )}
          <Row gutter={[22, 22]} className='button' justify='center'>
            <Col span={24}></Col>
            <Col xl={4} lg={4} md={4} sm={12} xs={12}>
              {editId ? (
                <Button
                  // className="filterButton"
                  className='cancelButton'
                  onClick={handleeditCancel}
                >
                  Cancel
                </Button>
              ) : (
                <Button
                  // className="filterButton"
                  className='cancelButton'
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              )}
            </Col>
            <Col xl={4} lg={4} md={4} sm={12} xs={12}>
              <Button className='editDesignationButton' htmlType='submit'>
                {editId ? 'Update' : 'Create'}
              </Button>
            </Col>
            <Col xl={8} lg={8} md={8} sm={12} xs={12}></Col>
          </Row>
        </Spin>
      </form>
    </Card>
  );
};

export default RestarantModalForm;
