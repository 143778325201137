import {
  Button,
  Card,
  Checkbox,
  Col,
  Collapse,
  Divider,
  Drawer,
  Input,
  message,
  Row,
  Select,
  Spin,
  Switch,
  TimePicker,
  Typography,
} from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  getRestaurantById,
  updateCapabilitiesRestaurantAction,
} from '../../Actions/RestaurantAction';
import { useParams } from 'react-router';
import {
  getSchedulePickup,
  updateSchedulPickup,
} from '../../Actions/SchedulePickupAction';
import moment from 'moment';
import {
  CloseOutlined,
  EditOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';

const ScheduledPickup = (props) => {
  const { editId, restaurant, setTabkey } = props;
  const dispatch = useDispatch();
  const {
    SchedulePickupData,
    isUpdateSchedulePickupLoading,
    isSchedulePickupLoading,
    restaurantdeatils,
  } = useSelector((state) => {
    const {
      SchedulePickupData,
      isUpdateSchedulePickupLoading,
      isSchedulePickupLoading,
    } = state.schedulePickup;
    const { restaurantdeatils } = state.restaurant;
    return {
      SchedulePickupData,
      restaurantdeatils,
      isUpdateSchedulePickupLoading,
      isSchedulePickupLoading,
    };
  });
  const [isFeatchOrgTiming, setFeatchOrgTiming] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [valChanged, setValChanged] = useState(false);
  const [selected, setSelected] = useState('');
  const [advancePickupLimitType, setAdvancePickupLimitType] = useState('');
  const [pickupTimeSlotSize, setPickupTimeSlotSize] = useState('');
  const [selectedAddressOption, setSelectedAddressOption] = useState(
    'Use organization address'
  );
  const [checkedValueScheduledPickup, setCheckedValueScheduledPickup] =
    useState(editId && restaurant?.scheduled_pickup ? true : false);
  const [
    checkedValueCashOnScheduledPickup,
    setCheckedValueCashOnScheduledPickup,
  ] = useState(
    editId && restaurant?.scheduled_pickup_cash_payment ? true : false
  );
  const [
    checkedValueOnlinePaymentScheduledPickup,
    setCheckedValueOnlinePaymentScheduledPickup,
  ] = useState(
    editId && restaurant?.scheduled_pickup_online_payment ? true : false
  );
  const { handleSubmit, control, reset, clearErrors, setValue } = useForm({
    defaultValues: {
      advance_pickup_limit: SchedulePickupData?.advance_pickup_limit || '',
    },
  });
  const options = ['12hrs', '24hrs', '48hrs', '72hrs'];
  const addressOptions = ['Use organization address', 'Add custom address'];
  const pickupTiming = SchedulePickupData?.pickup_timings;
  const orgTiming = restaurant?.organizations_detail?.operation_timing;

  useEffect(() => {
    if (SchedulePickupData) {
      setSelected(SchedulePickupData?.advance_order_time_limit);
      setAdvancePickupLimitType(SchedulePickupData?.advance_pickup_limit_type);
      setPickupTimeSlotSize(SchedulePickupData?.pickup_time_slot_size);
    }
  }, [SchedulePickupData]);

  useEffect(() => {
    dispatch(getSchedulePickup());
  }, []);
  const onChangeScheduledPickup = (e) => {
    setValChanged(true);
    setCheckedValueScheduledPickup(e);
  };
  const onChangeCashOnScheduledPickup = (e) => {
    setValChanged(true);
    setCheckedValueCashOnScheduledPickup(e);
  };
  const onChangeOnlinePaymentScheduledPickup = (e) => {
    setValChanged(true);
    setCheckedValueOnlinePaymentScheduledPickup(e);
  };

  useEffect(() => {
    if (SchedulePickupData) {
      setValue(
        'advance_pickup_limit',
        SchedulePickupData.advance_pickup_limit || ''
      );
    }
  }, [SchedulePickupData, setValue]);

  useEffect(() => {
    if (selectedAddressOption === 'Use organization address') {
      setValue('pickup_address_line_1', restaurantdeatils.address_line_1);
      setValue('pickup_address_line_2', restaurantdeatils.address_line_2);
      setValue('zipcode', restaurantdeatils.zip_code);
    } else {
      setValue(
        'pickup_address_line_1',
        SchedulePickupData.pickup_address_line_1
      );
      setValue(
        'pickup_address_line_2',
        SchedulePickupData.pickup_address_line_2
      );
      setValue('zipcode', SchedulePickupData.zipcode);
    }
  }, [selectedAddressOption]);

  const handleEditRestaurantMethod = (data) => {
    if (valChanged) {
      const capabilityData = {
        id: editId,
        scheduled_pickup: checkedValueScheduledPickup,
        scheduled_pickup_cash_payment: checkedValueCashOnScheduledPickup,
        scheduled_pickup_online_payment:
          checkedValueOnlinePaymentScheduledPickup,
      };

      dispatch(
        updateCapabilitiesRestaurantAction(
          capabilityData,
          UpdateCallBack,
          faliureUpdate
        )
      );
    }

    let updateSchedule =
      data.advance_pickup_limit !== '' ||
      selected !== '' ||
      advancePickupLimitType !== '' ||
      pickupTimeSlotSize !== '';

    if (updateSchedule) {
      const { scheduled_pickup, ...filteredData } = data;
      const scheduleData = {
        ...filteredData,
        id: SchedulePickupData.id,
        advance_order_time_limit: selected,
        advance_pickup_limit_type: advancePickupLimitType,
        advance_pickup_limit: Number(data.advance_pickup_limit) || 0,
        pickup_time_slot_size: pickupTimeSlotSize,
      };

      dispatch(
        updateSchedulPickup(scheduleData, UpdateCallBack, faliureUpdate)
      );
    }
  };

  const UpdateCallBack = () => {
    setTabkey('1');
    dispatch(getRestaurantById(editId));
    setTimeout(() => {
      message.success('Your Scheduled Pickup Updated Successfully');
    }, 1000);
  };
  const faliureUpdate = (failureResponse) => {
    setTimeout(() => {
      message.error(failureResponse);
    }, 1000);
  };

  const handleCancel = () => {
    clearErrors();
  };

  const fetchOrganizationTiming = () => {
    setFeatchOrgTiming(true);
  };

  return (
    <>
      <Spin spinning={isSchedulePickupLoading || isUpdateSchedulePickupLoading}>
        <form onSubmit={handleSubmit(handleEditRestaurantMethod)}>
          <Card>
            <Row gutter={[18, 18]} justify='space-between'>
              <Col span={20}>
                <Typography className='formHeading'>
                  Scheduled Pickup
                </Typography>
              </Col>
              <Col span={4} align='end'>
                <Controller
                  name='scheduled_pickup'
                  control={control}
                  defaultValue={false}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={checkedValueScheduledPickup}
                      onChange={(e) =>
                        onChangeScheduledPickup(e.target.checked)
                      }
                    />
                  )}
                />
              </Col>
              <Col span={24}>
                <Typography className='noteText'>
                  Note: Turn on the payment options according to your needs
                </Typography>
              </Col>
              <Col span={24}>
                <Row gutter={[0, 8]}>
                  <Col span={20}>
                    <Typography className='formHeading'>
                      Cash on Pickup
                    </Typography>
                  </Col>
                  <Col span={4} align='end'>
                    <Switch
                      checked={checkedValueCashOnScheduledPickup}
                      onChange={onChangeCashOnScheduledPickup}
                      size='small'
                    />
                  </Col>
                  <Col span={24}>
                    <Typography className='infoText'>
                      Customers pay immediately through digital methods when
                      placing the order.
                    </Typography>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row gutter={[0, 8]}>
                  <Col span={20}>
                    <Typography className='formHeading'>
                      Online Payment
                    </Typography>
                  </Col>
                  <Col span={4} align='end'>
                    <Switch
                      checked={checkedValueOnlinePaymentScheduledPickup}
                      onChange={onChangeOnlinePaymentScheduledPickup}
                      size='small'
                    />
                  </Col>
                  <Col span={24}>
                    <Typography className='infoText'>
                      Customers can choose to pay in cash when they arrive on
                      the pickup location to pickup their order.
                    </Typography>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row gutter={[0, 8]}>
                  <Col span={24}>
                    <Typography className='formHeading'>
                      Advance order time limit
                    </Typography>
                  </Col>
                  <Col span={24}>
                    <Typography className='infoText'>
                      Set how far in advance customers can place pickup orders
                    </Typography>
                  </Col>
                  <Col span={24}>
                    <div style={{ display: 'flex', gap: '8px' }}>
                      {options.map((option) => (
                        <Button
                          key={option}
                          onClick={() => setSelected(option)}
                          style={{
                            backgroundColor:
                              selected === option ? '#000' : '#fff',
                            color: selected === option ? '#fff' : '#000',
                            border: '1px solid #000',
                          }}
                        >
                          {option}
                        </Button>
                      ))}
                    </div>
                  </Col>
                  <Col span={24}>
                    <Typography className='infoText'>
                      Example: Restricting orders to be placed at least 24 hours
                      before the desired pickup time.
                    </Typography>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row gutter={[0, 8]}>
                  <Col span={24}>
                    <Typography className='formHeading'>
                      Advance pickup limit
                    </Typography>
                  </Col>
                  <Col span={24}>
                    <Typography className='infoText'>
                      Control how early customers can book a pickup order.
                    </Typography>
                  </Col>
                  <Col span={24}>
                    <Row gutter={8}>
                      <Col span={18}>
                        <Controller
                          as={<Input />}
                          name='advance_pickup_limit'
                          control={control}
                          type='number'
                          className='inputLabel'
                        />
                      </Col>
                      <Col span={6}>
                        <Select
                          options={[
                            { value: 'months', label: 'Months' },
                            { value: 'days', label: 'Days' },
                          ]}
                          style={{ width: '100%' }}
                          value={advancePickupLimitType}
                          onChange={(value) => setAdvancePickupLimitType(value)}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col span={24}>
                    <Typography className='infoText'>
                      Example, you can set the limit to 7 days or 1 month,
                      enabling customers to conveniently plan their pickups
                      within this timeframe.
                    </Typography>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row gutter={[0, 8]}>
                  <Col span={24}>
                    <Row>
                      <Col span={20}>
                        <Typography className='formHeading'>
                          Pickup timings
                        </Typography>
                      </Col>
                      <Col span={4}>
                        <Row justify='end'>
                          <EditOutlined
                            style={{ fontSize: '20px' }}
                            onClick={() => setOpenDrawer(true)}
                          />
                        </Row>
                      </Col>{' '}
                    </Row>
                  </Col>
                  <Col span={24}>
                    <Typography className='infoText'>
                      Set specific pickup timings
                    </Typography>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row gutter={[0, 8]}>
                  <Col span={24}>
                    <Typography className='formHeading'>
                      Fetch Organization’s operational timings
                    </Typography>
                  </Col>
                  <Col>
                    <Button
                      className='editDesignationButton'
                      onClick={fetchOrganizationTiming}
                    >
                      Fetch
                    </Button>
                  </Col>
                </Row>
                <Row gutter={[0, 16]} style={{ marginTop: '1rem' }}>
                  {Object.keys(pickupTiming || {}).map((day, index) => {
                    const isCloseDay = pickupTiming?.[day]?.is_open;
                    return (
                      <Col
                        span={24}
                        key={index}
                        style={{ opacity: !isCloseDay ? 0.5 : 1 }}
                      >
                        <Row justify='space-between' align='middle'>
                          <Col flex='auto'>
                            <Typography className='formHeading'>
                              {day}
                            </Typography>
                          </Col>
                          <Col>
                            {pickupTiming?.[day]?.hours?.map((hours, index) => (
                              <Typography
                                className='formHeading'
                                key={index}
                                style={{ display: 'block' }}
                              >
                                {`${moment(hours?.start_time).format(
                                  'hh:mm A'
                                )} - ${moment(hours?.end_time).format(
                                  'hh:mm A'
                                )}`}
                              </Typography>
                            ))}
                          </Col>
                        </Row>
                      </Col>
                    );
                  })}
                </Row>
              </Col>

              <Col span={24}>
                <Row gutter={[0, 5]}>
                  <Col span={24}>
                    <Typography className='formHeading'>
                      Pickup time slot size
                    </Typography>
                  </Col>
                  <Col span={24}>
                    <Typography className='infoText'>
                      e.g. 30 mins slots will show open slots at 8:00, 8:30,
                      9:00 etc.
                    </Typography>
                  </Col>
                  <Col span={24}>
                    <Select
                      options={[
                        { value: 15, label: '15 mins' },
                        { value: 30, label: '30 mins' },
                        { value: 45, label: '45 mins' },
                        { value: 60, label: '1 hour' },
                        { value: 120, label: '2 hours' },
                      ]}
                      style={{ width: '100%' }}
                      value={pickupTimeSlotSize}
                      onChange={(value) => setPickupTimeSlotSize(value)}
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row gutter={[0, 8]}>
                  <Col span={24}>
                    <Typography className='formHeading'>
                      Pickup address
                    </Typography>
                  </Col>
                  <Col span={24}>
                    <Typography className='infoText'>
                      Provide the exact address where customers can collect
                      their orders.
                    </Typography>
                  </Col>
                  <Col span={24}>
                    <div style={{ display: 'flex', gap: '8px' }}>
                      {addressOptions.map((option) => (
                        <Button
                          key={option}
                          onClick={() => setSelectedAddressOption(option)}
                          style={{
                            backgroundColor:
                              selectedAddressOption === option
                                ? '#000'
                                : '#fff',
                            color:
                              selectedAddressOption === option
                                ? '#fff'
                                : '#000',
                            border: '1px solid #000',
                          }}
                        >
                          {option}
                        </Button>
                      ))}
                    </div>
                  </Col>
                  <Col span={24}>
                    <Row gutter={2}>
                      <Col span={24}>
                        <Typography className='formHeading'>
                          Address Line 1
                        </Typography>
                      </Col>
                      <Col span={24}>
                        <Controller
                          as={<Input />}
                          name='pickup_address_line_1'
                          control={control}
                          className='inputLabel'
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col span={24}>
                    <Row gutter={2}>
                      <Col span={24}>
                        <Typography className='formHeading'>
                          Address Line 2
                        </Typography>
                      </Col>
                      <Col span={24}>
                        <Controller
                          as={<Input />}
                          name='pickup_address_line_2'
                          control={control}
                          className='inputLabel'
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col span={24}>
                    <Row gutter={[10, 10]}>
                      <Col span={12}>
                        <Row>
                          <Col span={24}>
                            <Typography className='formHeading'>
                              City
                            </Typography>
                          </Col>
                          <Col span={24}>
                            <Input
                              defaultValue={editId && restaurantdeatils?.city}
                              disabled
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col span={12}>
                        <Row>
                          <Col span={24}>
                            <Typography className='formHeading'>
                              Zip code
                            </Typography>
                          </Col>
                          <Col span={24}>
                            <Controller
                              as={<Input />}
                              name='zipcode'
                              control={control}
                              className='inputLabel'
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={24}>
                    <Row gutter={[10, 10]}>
                      <Col span={12}>
                        <Row>
                          <Col span={24}>
                            <Typography className='formHeading'>
                              State
                            </Typography>
                          </Col>
                          <Col span={24}>
                            <Input
                              defaultValue={editId && restaurantdeatils?.state}
                              disabled
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col span={12}>
                        <Row>
                          <Col span={24}>
                            <Typography className='formHeading'>
                              Country
                            </Typography>
                          </Col>
                          <Col span={24}>
                            <Input
                              defaultValue={
                                editId && restaurantdeatils?.country
                              }
                              disabled
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row gutter={[22, 22]} className='button' justify='center'>
                      <Col span={24}></Col>
                      <Col xl={4} lg={8} md={8} sm={10} xs={10}>
                        <Button className='cancelButton' onClick={handleCancel}>
                          Cancel
                        </Button>
                      </Col>
                      <Col xl={4} lg={8} md={8} sm={10} xs={10}>
                        <Button
                          className='editDesignationButton'
                          htmlType='submit'
                        >
                          Update
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
        </form>
      </Spin>
      <Drawer
        title='Edit Details'
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        width={400} // Adjust width as needed
      >
        <EditPickupTiming
          data={!isFeatchOrgTiming ? pickupTiming : orgTiming}
          scheduleId={SchedulePickupData?.id}
          setFeatchOrgTiming={setFeatchOrgTiming}
          setOpenDrawer={setOpenDrawer}
          isUpdateSchedulePickupLoading={isUpdateSchedulePickupLoading}
        />
      </Drawer>
    </>
  );
};

const EditPickupTiming = (props) => {
  const {
    data,
    scheduleId,
    setFeatchOrgTiming,
    setOpenDrawer,
    isUpdateSchedulePickupLoading,
  } = props;

  // const { isUpdateSchedulePickupConfigLoading } = useSelector((state) => {
  //   const { organization } = state;
  // //   const { isUpdateSchedulePickupConfigLoading } = organization;
  // //   return { isUpdateSchedulePickupConfigLoading };
  // // });

  const dispatch = useDispatch();
  const [pickupTiming, setPickupTiming] = useState(data);
  const [expanded, setExpanded] = useState(false);

  const allDayTimeSlot = {
    start_time: moment().startOf('day').format('ddd MMM DD YYYY 00:00:00 ZZ'),
    end_time: moment().endOf('day').format('ddd MMM DD YYYY 23:59:59 ZZ'),
  };
  const handleAccordionChange = useCallback(
    (panel) => (event, isExpanded) => {
      if (
        pickupTiming?.[panel].is_open &&
        !(
          moment(pickupTiming?.[panel].hours?.[0]?.start_time).format(
            'HH:mm:ss'
          ) === '00:00:00' &&
          moment(pickupTiming?.[panel].hours?.[0]?.end_time).format(
            'HH:mm:ss'
          ) === '23:59:59'
        )
      ) {
        setExpanded(isExpanded ? panel : false);
      }
    },
    [pickupTiming]
  );

  const handleSelectChange = useCallback((day, value) => {
    setPickupTiming((prev) => ({
      ...prev,
      [day]: {
        ...prev[day],
        is_open: value !== 'closed',
        hours:
          value === 'allDay'
            ? [allDayTimeSlot]
            : value === 'open'
            ? data[day].hours
            : data[day].hours,
      },
    }));

    if (value === 'open') {
      setExpanded(day);
    } else {
      setExpanded(false);
    }
  }, []);

  const handleAddTimeSlot = useCallback((day) => {
    setPickupTiming((prev) => ({
      ...prev,
      [day]: {
        ...prev?.[day],
        hours: [
          ...prev?.[day]?.hours,
          {
            start_time: moment()
              .startOf('day')
              .format('ddd MMM DD YYYY 00:00:00 ZZ'),
            end_time: moment()
              .startOf('day')
              .add(1, 'hour')
              .format('ddd MMM DD YYYY 01:00:00 ZZ'),
          },
        ],
      },
    }));
  }, []);

  const handleRemoveTimeSlot = useCallback((day, index) => {
    setPickupTiming((prev) => {
      const updatedHours = prev?.[day].hours.filter((_, i) => i !== index);
      return {
        ...prev,
        [day]: {
          ...prev?.[day],
          hours:
            updatedHours.length > 0
              ? updatedHours
              : [
                  {
                    start_time: moment()
                      .startOf('day')
                      .format('ddd MMM DD YYYY 00:00:00 ZZ'),
                    end_time: moment()
                      .startOf('day')
                      .add(1, 'hour')
                      .format('ddd MMM DD YYYY 01:00:00 ZZ'),
                  },
                ],
        },
      };
    });
  }, []);

  const handleTimeChange = useCallback((day, index, field, value) => {
    setPickupTiming((prev) => {
      const updatedHours = [...prev[day]?.hours];
      updatedHours[index][field] = value.format('ddd MMM DD YYYY HH:mm:ss ZZ');
      return {
        ...prev,
        [day]: {
          ...prev[day],
          hours: updatedHours,
        },
      };
    });
  }, []);

  const onChangedHourstSubmit = () => {
    dispatch(
      updateSchedulPickup(
        { id: scheduleId, pickup_timings: { ...pickupTiming } },
        () => {
          // onClose();
          setOpenDrawer(false);
          setFeatchOrgTiming(false);
          dispatch(getSchedulePickup());
        }
      )
    );
  };

  const onCancelClick = () => {
    setPickupTiming(data);
  };

  const { Panel } = Collapse;
  const { Option } = Select;
  return (
    <Spin spinning={isUpdateSchedulePickupLoading}>
      <Row gutter={[0, 16]}>
        <Col span={24}>
          <Collapse
            activeKey={Object.keys(pickupTiming).filter(
              (day) => expanded === day && pickupTiming?.[day].is_open
            )}
            onChange={(keys) => {
              if (keys.length) {
                const newExpanded = keys[keys.length - 1];
                if (pickupTiming[newExpanded].is_open) {
                  handleAccordionChange(newExpanded);
                }
              } else {
                handleAccordionChange(null);
              }
            }}
          >
            {Object.keys(pickupTiming).map((day) => (
              <Panel
                key={day}
                header={
                  <Row
                    justify='space-between'
                    align='middle'
                    wrap={false}
                    style={{ width: '100%', display: 'flex' }}
                  >
                    <Col flex='1'>
                      <Typography className='formHeading'>{day}</Typography>
                    </Col>
                    <Col flex='none'>
                      <Select
                        value={
                          pickupTiming?.[day].is_open
                            ? moment(
                                pickupTiming[day].hours?.[0]?.start_time
                              ).format('HH:mm:ss') === '00:00:00' &&
                              moment(
                                pickupTiming[day].hours?.[0]?.end_time
                              ).format('HH:mm:ss') === '23:59:59'
                              ? 'allDay'
                              : 'open'
                            : 'closed'
                        }
                        onChange={(value) => {
                          handleSelectChange(day, value);
                          if (value === 'closed') {
                            handleAccordionChange(null);
                          }
                        }}
                        size='small'
                        style={{ width: 100 }}
                        onClick={(e) => e.stopPropagation()}
                        className='formHeading'
                        // onMouseDown={(e) => e.stopPropagation()}
                      >
                        <Option value='open' className='formHeading'>
                          Open
                        </Option>
                        <Option value='allDay' className='formHeading'>
                          All Day
                        </Option>
                        <Option value='closed' className='formHeading'>
                          Closed
                        </Option>
                      </Select>
                    </Col>
                  </Row>
                }
                // collapsible={pickupTiming?.[day].is_open ? 'header' : 'disabled'}
              >
                <Row gutter={[8, 8]} align='middle'>
                  {pickupTiming?.[day]?.hours?.map((slot, index) => (
                    <React.Fragment key={index}>
                      <Col span={10}>
                        <TimePicker
                          value={
                            slot.start_time ? moment(slot.start_time) : null
                          }
                          onChange={(newValue) =>
                            handleTimeChange(day, index, 'start_time', newValue)
                          }
                          format='HH:mm'
                          style={{ width: '100%' }}
                        />
                      </Col>
                      <Col span={10}>
                        <TimePicker
                          value={slot.end_time ? moment(slot.end_time) : null}
                          onChange={(newValue) =>
                            handleTimeChange(day, index, 'end_time', newValue)
                          }
                          format='HH:mm'
                          style={{ width: '100%' }}
                        />
                      </Col>
                      <Col span={4}>
                        {index === 0 ? (
                          <PlusOutlined
                            onClick={() => handleAddTimeSlot(day)}
                            style={{ cursor: 'pointer' }}
                          />
                        ) : (
                          <MinusCircleOutlined
                            onClick={() => handleRemoveTimeSlot(day, index)}
                            style={{ cursor: 'pointer' }}
                          />
                        )}
                      </Col>
                    </React.Fragment>
                  ))}
                </Row>
              </Panel>
            ))}
          </Collapse>
        </Col>
        <Col span={24}>
          <Row gutter={[8, 8]}>
            <Col span={12}>
              <Button className='cancelButton' onClick={onCancelClick}>
                Cancel
              </Button>
            </Col>
            <Col span={12}>
              <Button
                className='editDesignationButton'
                onClick={onChangedHourstSubmit}
              >
                Save
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Spin>
  );
};

export default ScheduledPickup;
