import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import { AutoComplete, Input, Spin } from 'antd';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

const SearchComponent = ({
  search,
  placeholder,
  searchMethod,
  replaceMethod,
  successCallback,
  setCustomerOptions,
  customerOptions,
  setDropdownOpen,
  dropdownOpen,
  isCustomerLoading,
  customerId,
  type,
  startDate,
  endDate,
  additionalParams,
}) => {
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const dispatch = useDispatch();

  const handleSearch = (value) => {
    setInputValue(value);
    if (!value) {
      if (type === 'orders' || type === 'queue') {
        dispatch(searchMethod(`?page=1&customer_id=${customerId}`));
      } else {
        if (additionalParams) {
          const paramsString = new URLSearchParams(additionalParams).toString();
          dispatch(searchMethod(`?${paramsString}`));
        } else {
          dispatch(searchMethod('?page=1'));
        }
      }

      setOptions([]);
      if (customerOptions) {
        setDropdownOpen(false);
        setCustomerOptions([]);
      }
    } else {
      const res = search?.map((obj) => ({
        value: `${value} as ${obj.label}`,
        label: `${value} as ${obj.label}`,
        replace: obj.replace,
      }));
      setOptions(res);
      if (customerOptions) {
        setDropdownOpen(true);
        setCustomerOptions([]);
      }
    }
  };

  const handleClearInput = () => {
    setInputValue('');
    if (customerId) {
      dispatch(
        searchMethod(
          `?page=1&customer_id=${customerId}&from_date=${startDate}&end_date=${endDate}`
        )
      );
    } else if (additionalParams) {
      const paramsString = new URLSearchParams(additionalParams).toString();
      dispatch(searchMethod(`?${paramsString}`));
    } else {
      dispatch(searchMethod('?page=1'));
    }
    setOptions([]);
    if (customerOptions) {
      setCustomerOptions([]);
    }
  };

  const customRender = (item) => {
    let isAfterAs = false;

    return item.label.split(' ').map((part, index) => {
      let fontFamily = 'Circular-900';
      if (isAfterAs) {
        fontFamily = 'Circular-700';
      }
      if (part === 'as') {
        isAfterAs = true;
      }

      return (
        <span
          key={index}
          style={{
            fontStyle: part === 'as' ? 'italic' : 'normal',
            fontFamily: fontFamily,
          }}
        >
          {part}{' '}
        </span>
      );
    });
  };

  const mappedOptions = isCustomerLoading
    ? [
        {
          label: (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                padding: '8px',
              }}
            >
              <Spin spinning={isCustomerLoading} />
            </div>
          ),
          value: 'loading',
        },
      ]
    : (customerOptions?.length > 0 ? customerOptions : options)?.map(
        (option) => ({
          value: option?.value,
          label: (
            <div>
              <SearchOutlined style={{ marginRight: 8, color: '#7b7b7b' }} />
              {customRender(option)}
            </div>
          ),
        })
      );

  const handleSelect = (selectedOption) => {
    const selectedCustomer = customerOptions?.find(
      (option) => option.value === selectedOption
    );
    if (selectedCustomer) {
      if (customerOptions) {
        setDropdownOpen(false);
      }
      dispatch(searchMethod(`?user_id=${selectedCustomer.id}`));
    } else {
      let selectedKey, selectedLabel, isReplace;

      search?.forEach((obj) => {
        if (selectedOption.includes(obj.label)) {
          selectedKey = obj.key;
          selectedLabel = obj.label;
          isReplace = obj.replace;
        }
      });

      const searchValue = selectedOption
        .replace(` as ${selectedLabel}`, '')
        .trim();
      setInputValue(searchValue);

      if (isReplace) {
        dispatch(
          replaceMethod(`?${selectedKey}=${searchValue}`, successCallback)
        );
      } else if (additionalParams) {
        const paramsString = new URLSearchParams(additionalParams).toString();
        if (type === 'orders')
          dispatch(
            searchMethod(`?${selectedKey}=ORD%23${searchValue}&${paramsString}`)
          );
        else {
          dispatch(
            searchMethod(`?${selectedKey}=${searchValue}&${paramsString}`)
          );
          if (customerOptions) {
            setDropdownOpen(false);
          }
        }
      } else {
        dispatch(searchMethod(`?${selectedKey}=${searchValue}`));
        if (customerOptions) {
          setDropdownOpen(false);
        }
      }
    }
  };

  return (
    <AutoComplete
      style={{
        width: '100%',
      }}
      popupClassName='autoCompleteDropdown'
      onSearch={handleSearch}
      onSelect={handleSelect}
      value={inputValue}
      onChange={setInputValue}
      options={mappedOptions}
      open={customerOptions ? dropdownOpen : undefined}
    >
      <Input
        size='large'
        className='autoCompleteInput'
        prefix={<SearchOutlined />}
        placeholder={placeholder}
        suffix={
          inputValue ? (
            <CloseOutlined
              onClick={handleClearInput}
              style={{ cursor: 'pointer' }}
            />
          ) : null
        }
      />
    </AutoComplete>
  );
};

export default SearchComponent;
