import { combineReducers } from 'redux';
import authReducer from './authReducer';
import { authenticationReducer } from './authenticReducer';
import { restaurantReducer } from './restaurantReducer';
import { countryReducer } from './countryReducer';
import { stateReducer } from './stateReducar';
import { timeZoneReducer } from './TimeZoneReducer';
import { taxationReducer } from './taxationReducer';
import { categoryReducer } from './categoryReducer';
import { categoryItemReducer } from './categoryItemReducer';
import { orderProcessReducer } from './orderPRocessReducer';
import { qrCodesReducer } from './qrCodeReducer';
import { organizationTableReducer } from './organizationTableReducer';
import { stationsReducer } from './stationsReducer';
import { paymentModeReducer } from './paymentModeReducer';
import { stationsItemReducer } from './stationItemReducer';
import { tablesReducer } from './tablesReducer';
import { printerReducer } from './printerReducer';
import { surveyReducer } from './surveyReducer';
import { surveyQuestionReducer } from './surveyQuestionReducer';
import { itemTypesReducer } from './itemTypesReducer';
import { itemReducer } from './itemReducer';
import { CfdImagesReducer } from './CfdImagesReducer';
import { imageReducer } from './ImageReducer';
import { employeeReducer } from './employeeReducer';
import { layoutReducer } from './layoutReducer';
import { organizationUserReducer } from './organizationUserReducer';
import { UserThemesReducer } from './userThemesReducer';
import { customerReducer } from './customerReducer';
import { customizationReducer } from './customizationReducer';
import { customizationItemReducer } from './customizationItemReducer';
import { orderReducer } from './orderReducer';
import { subscriptionReducer } from './subscriptionReducer';

import { supplierReducer } from './supplierReducer';

import { userReducer } from './userReducer';
import { featuresReducer } from './featuresReducer';
import { featuresFlagReducer } from './featureFlagReducer';
import { languagesReducer } from './languagesReducer';
import { waiterReducer } from './waiterReducer';
import { queueReducer } from './queueReducer';
import { schedulePickupReducer } from './schedulePickupReducer';

const rootReducer = combineReducers({
  auth: authReducer,
  authentication: authenticationReducer,
  restaurant: restaurantReducer,
  country: countryReducer,
  states: stateReducer,
  timeZones: timeZoneReducer,
  taxation: taxationReducer,
  category: categoryReducer,
  categoryItem: categoryItemReducer,
  orderProcess: orderProcessReducer,
  qrCodes: qrCodesReducer,
  organizationTable: organizationTableReducer,
  stations: stationsReducer,
  paymentMode: paymentModeReducer,
  stationsItem: stationsItemReducer,
  tables: tablesReducer,
  printer: printerReducer,
  survey: surveyReducer,
  surveyQuestion: surveyQuestionReducer,
  item: itemReducer,
  itemType: itemTypesReducer,
  CfdImages: CfdImagesReducer,
  image: imageReducer,
  employees: employeeReducer,
  layout: layoutReducer,
  organizationUser: organizationUserReducer,
  userThemes: UserThemesReducer,
  customer: customerReducer,
  customization: customizationReducer,
  customizationItem: customizationItemReducer,
  order: orderReducer,
  subscription: subscriptionReducer,
  supplier: supplierReducer,
  user: userReducer,
  feature: featuresReducer,
  featuresFlag: featuresFlagReducer,
  languages: languagesReducer,
  waiter: waiterReducer,
  queue: queueReducer,
  schedulePickup: schedulePickupReducer,
});
export default rootReducer;
