import constants from '../Utils/constants';
const initialState = {
  isSchedulePickupLoading: false,
  isSchedulePickupByIdLoading: false,
  isUpdateSchedulePickupLoading: false,
  SchedulePickupData: [],
  SchedulePickup: {},
};

export const schedulePickupReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_SCHEDULE_PICKUP_REQUEST:
      return {
        ...state,
        isSchedulePickupLoading: true,
      };
    case constants.GET_SCHEDULE_PICKUP_SUCCESS:
      return {
        ...state,
        isSchedulePickupLoading: false,
        SchedulePickupData: action.payload,
      };
    case constants.GET_SCHEDULE_PICKUP_FAILURE:
      return {
        ...state,
        isSchedulePickupLoading: false,
      };
    case constants.GET_SCHEDULE_PICKUP_BY_ID_REQUEST:
      return {
        ...state,
        isSchedulePickupByIdLoading: true,
      };
    case constants.GET_SCHEDULE_PICKUP_BY_ID_SUCCESS:
      return {
        ...state,
        isSchedulePickupByIdLoading: false,
        SchedulePickup: action.payload,
      };
    case constants.GET_SCHEDULE_PICKUP_BY_ID_FAILURE:
      return {
        ...state,
        isSchedulePickupByIdLoading: false,
      };
    case constants.UPDATE_SCHEDULE_PICKUP_REQUEST:
      return {
        ...state,
        isUpdateSchedulePickupLoading: true,
      };
    case constants.UPDATE_SCHEDULE_PICKUP_SUCCESS:
      return {
        ...state,
        isUpdateSchedulePickupLoading: false,
      };
    case constants.UPDATE_SCHEDULE_PICKUP_FAILURE:
      return {
        ...state,
        isUpdateSchedulePickupLoading: false,
      };
    default:
      return state;
  }
};
