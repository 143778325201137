import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Pagination,
  Typography,
  Spin,
  Tooltip,
  Breadcrumb,
  Button,
  Select,
  Input,
  InputNumber,
} from 'antd';
import _, { find, gte, replace, toNumber } from 'lodash';
import '../CustomTable/CustomTable.css';
import { useDispatch, useSelector } from 'react-redux';
import CustomTable from '../CustomTable/CustomTable';
import { useNavigate, useParams } from 'react-router-dom';
import { getCustomers } from '../../Actions/CustomerAction';
import { FaCircle } from 'react-icons/fa6';
import { getOrderById, getOrders } from '../../Actions/OrderAction';
import moment from 'moment';
import { getOrderProcess } from '../../Actions/OrderProcessAction';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import DateRangeSelector from '../../Utils/DateRangeSelector';
import SearchComponent from '../../Utils/SearchComponent';
import { MdCloudUpload } from 'react-icons/md';

const Orders = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  });
  const [prices, setPrices] = useState({ from: null, to: null });
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    restaurant,
    isOrderLoading,
    allOrders,
    allOrderProcess,
    isCustomerLoading,
  } = useSelector((state) => {
    const { restaurant } = state.restaurant;
    const { allOrders, isOrderLoading } = state.order;
    const { allOrderProcess } = state.orderProcess;
    const { isCustomerLoading } = state.customer;
    return {
      restaurant,
      isOrderLoading,
      allOrders,
      allOrderProcess,
      isCustomerLoading,
    };
  });

  const { orgId } = useParams();
  const { length } = allOrders || {};
  const organizationCurrencySymbol =
    restaurant?.organizations_detail?.default_currency_symbol;

  useEffect(() => {
    if (selectionRange) {
      dispatch(
        getOrders(
          `?page=${currentPage}&from_date=${formatDate(
            selectionRange.startDate
          )}&to_date=${formatDate(selectionRange.endDate)}`
        )
      );
    }
    dispatch(
      getOrderProcess(`api/organization_order_processes?is_sequence=true`)
    );
  }, []);

  const mapDataForExport = () => {
    return allOrders.orders.map(
      ({
        user,
        order_type,
        order_created,
        display_total,
        payment_amount,
        order_number,
        order_status,
      }) => {
        return {
          'Order number': order_number,
          'Order status': order_status,
          'Customer details':
            user?.first_name && user?.last_name
              ? `${user.first_name} ${user.last_name}`
              : `${user?.country_code} ${user?.phone}`,
          'Order type': order_type,
          'Order date and time':
            moment(order_created).format('DD/MM/YYYY hh:mm A'),
          'Payment status':
            Number(payment_amount) >= Number(display_total)
              ? `${organizationCurrencySymbol}${display_total} Paid`
              : `${organizationCurrencySymbol}${display_total} Unpaid`,
          'Order price': `${organizationCurrencySymbol}${display_total}`,
        };
      }
    );
  };
  const exportToExcel = () => {
    const exportData = mapDataForExport();
    const ws = XLSX.utils.json_to_sheet(exportData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Orders');
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    const filename = `orders ${moment().format('MM_DD_YYYY')}.xlsx`;
    saveAs(blob, filename);
  };
  const formatDate = (date) => {
    return date.toLocaleDateString('en-GB').replace(/\//g, '-');
  };

  const handleSelectDateRange = (ranges) => {
    const { startDate, endDate } = ranges.selection;
    setSelectionRange({ startDate, endDate, key: 'selection' });

    let url = `?page=${currentPage}&from_date=${formatDate(
      startDate
    )}&to_date=${formatDate(endDate)}`;

    if (selectedStatus) {
      url += `&order_status_id=${selectedStatus}`;
    }

    if (prices.from) url += `&price_from=${prices.from}`;
    if (prices.to) url += `&price_to=${prices.to}`;

    dispatch(getOrders(url));
  };

  const options = allOrderProcess?.map((data) => {
    return {
      value: data?.id,
      label: (
        <Typography style={{ fontFamily: 'Circular-400', wordSpacing: '-4px' }}>
          {data?.name}
        </Typography>
      ),
    };
  });
  const selectedLabel = allOrderProcess?.find(
    (item) => item.id === selectedStatus
  )?.name;

  const handleSelectStatus = (value) => {
    setSelectedStatus(value);
    let url = `?page=${currentPage}&order_status_id=${value}`;

    if (selectionRange) {
      url += `&from_date=${formatDate(
        selectionRange.startDate
      )}&to_date=${formatDate(selectionRange.endDate)}`;
    }

    if (prices.from) url += `&price_from=${prices.from}`;
    if (prices.to) url += `&price_to=${prices.to}`;

    dispatch(getOrders(url));
  };

  const handlePaginationMethod = (pageVal) => {
    setCurrentPage(pageVal);

    let url = `?page=${pageVal}`;

    if (selectionRange) {
      url += `&from_date=${formatDate(
        selectionRange.startDate
      )}&to_date=${formatDate(selectionRange.endDate)}`;
    }

    if (selectedStatus) {
      url += `&order_status_id=${selectedStatus}`;
    }

    if (prices.from) url += `&price_from=${prices.from}`;
    if (prices.to) url += `&price_to=${prices.to}`;

    dispatch(getOrders(url));
  };

  const handleChangePrice = (type, event) => {
    const price = event * 100;

    setPrices((prevPrices) => {
      const updatedPrices = { ...prevPrices, [type]: price };
      let url = `?page=${currentPage}`;

      if (selectionRange) {
        url += `&from_date=${formatDate(
          selectionRange.startDate
        )}&to_date=${formatDate(selectionRange.endDate)}`;
      }

      if (selectedStatus) {
        url += `&order_status_id=${selectedStatus}`;
      }

      if (updatedPrices.from) url += `&price_from=${updatedPrices.from}`;
      if (updatedPrices.to) url += `&price_to=${updatedPrices.to}`;

      dispatch(getOrders(url));
      return updatedPrices;
    });
  };

  const successCallback = (response) => {
    if (response?.customers?.length) {
      const optionsData = response.customers.map((customer) => ({
        value: `${customer?.name} - ${customer?.phone_with_country_code}`,
        label: `${customer?.name} - ${customer?.phone_with_country_code}`,
        id: customer.id,
      }));
      setCustomerOptions(optionsData);
      setDropdownOpen(true);
    }
  };
  const handleOpen = (Id) => {
    navigate(`/restaurants/${orgId}/orders/${Id}/orderdetails`);
  };

  const handleCloseRestaurant = () => {
    navigate('/restaurants');
    dispatch({
      type: 'GET_SELECTED_RESTAURANT_ID',
      payload: '',
    });
  };

  const handleCloseOrganization = () => {
    navigate(`/restaurants/${orgId}`);
  };

  const Columns = [
    {
      title: 'Order Number',
      dataIndex: 'order_number',
      width: '3%',
      render: (Id, { id, order_number }) => {
        return (
          <Row>
            <Col style={{ cursor: 'pointer' }}>
              <Tooltip
                title={`View ${order_number} Details`}
                onClick={() => handleOpen(id)}
              >
                <Typography>{order_number}</Typography>
              </Tooltip>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Order Status',
      dataIndex: 'order_status',
      width: '5%',
      render: (order_status, { order_status_id }) => {
        const orderStatusColor = find(allOrderProcess, [
          'id',
          order_status_id,
        ])?.process_color;
        return (
          <Row>
            <Col>
              <Typography style={{ color: orderStatusColor }}>
                <FaCircle className='circleIcon' /> {order_status}
              </Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Customer details',
      dataIndex: 'user',
      width: '5%',
      render: (user) => {
        return (
          <Row>
            <Col>
              <Typography>
                {' '}
                {user?.first_name && user?.last_name
                  ? `${user.first_name} ${user.last_name}`
                  : user?.country_code && user?.phone
                  ? `${user.country_code} ${user.phone}`
                  : '-'}
              </Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Order Type',
      dataIndex: 'order_type',
      width: '5%',
      render: (order_type) => {
        return (
          <Row>
            <Col>
              <Typography>{order_type}</Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Ordered Date and Time',
      dataIndex: 'order_created',
      width: '6%',
      render: (order_created) => {
        return (
          <Row>
            <Col>
              <Typography>
                {moment(order_created).format('DD/MM/YYYY hh:mm A')}
              </Typography>
            </Col>
          </Row>
        );
      },
    },

    {
      title: 'Payment status',
      dataIndex: 'display_total',
      width: '5%',
      render: (display_total, { payment_amount }) => {
        const isAmountPaid = gte(
          toNumber(payment_amount),
          toNumber(display_total)
        );
        return (
          <Row>
            <Col>
              <Typography
                style={{ color: isAmountPaid ? '#219653' : '#eb5757' }}
              >
                {isAmountPaid
                  ? `${organizationCurrencySymbol}${display_total} Paid`
                  : `${organizationCurrencySymbol}${display_total} Unpaid`}
              </Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Order Price',
      dataIndex: 'display_total',
      width: '2%',
      render: (display_total) => {
        return (
          <Row>
            <Col>
              <Typography>
                {organizationCurrencySymbol}
                {display_total}
              </Typography>
            </Col>
          </Row>
        );
      },
    },
  ];

  return (
    <Row gutter={[24, 24]}>
      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
        <Row gutter={[24, 24]}>
          <Col xl={23} lg={23} md={23} sm={23} xs={23}>
            <Breadcrumb separator='>'>
              <Breadcrumb.Item
                onClick={() => handleCloseRestaurant()}
                className='breadcrumRestaurant'
              >
                Restaurants
              </Breadcrumb.Item>
              <Breadcrumb.Item
                onClick={() => handleCloseOrganization()}
                className='breadcrumRestaurant'
              >
                {restaurant?.name}
              </Breadcrumb.Item>
              <Breadcrumb.Item className='breadcrumUsers'>
                Orders
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          <Col span={24}>
            <Typography className='heading'>Orders</Typography>
          </Col>
          <Col xs={24} sm={24} md={20} lg={14} xl={18}>
            <Row gutter={[10, 10]}>
              <Col>
                <DateRangeSelector
                  selectionRange={selectionRange}
                  onChange={handleSelectDateRange}
                  type='orders'
                />
              </Col>
              <Col>
                <Select
                  size='large'
                  placeholder='Select Status'
                  style={{
                    width: 200,
                    // marginBottom: 20,
                    fontFamily: 'Circular-400',
                    fontSize: '14px',
                  }}
                  onChange={handleSelectStatus}
                  value={
                    selectedStatus ? `Status: ${selectedLabel}` : undefined
                  }
                  options={options}
                />
              </Col>
              <Col>
                <InputNumber
                  className='inputNumber'
                  size='large'
                  onChange={(e) => handleChangePrice('from', e)}
                  placeholder='Price from'
                />{' '}
                -{' '}
                <InputNumber
                  className='inputNumber'
                  size='large'
                  onChange={(e) => handleChangePrice('to', e)}
                  placeholder='Price to'
                />
              </Col>
              <Col xs={24} sm={24} md={24} lg={18} xl={12}>
                <SearchComponent
                  search={[
                    { key: 'order_number', label: 'Order number' },
                    { key: 'token_number', label: 'Token number' },
                    { key: 'name', label: 'Name', replace: true },
                    { key: 'phone', label: 'Phone', replace: true },
                  ]}
                  placeholder='Search by order number/ token number/ name/ contact number'
                  searchMethod={getOrders}
                  replaceMethod={getCustomers}
                  successCallback={successCallback}
                  setCustomerOptions={setCustomerOptions}
                  customerOptions={customerOptions}
                  setDropdownOpen={setDropdownOpen}
                  dropdownOpen={dropdownOpen}
                  isCustomerLoading={isCustomerLoading}
                />
              </Col>
            </Row>
          </Col>

          <Col xs={24} sm={24} md={6} lg={10} xl={6}>
            <Row justify='end'>
              <Col>
                {' '}
                <Button
                  size='large'
                  className='inputNumber'
                  style={{ padding: '0 50px' }}
                  onClick={exportToExcel}
                >
                  <MdCloudUpload
                    style={{ marginRight: '10px', fontSize: '20px' }}
                  />
                  Export
                </Button>
              </Col>
            </Row>
          </Col>

          <Col span={24}>
            <Spin spinning={isOrderLoading}>
              <CustomTable
                columns={Columns}
                currentPage={currentPage}
                data={allOrders?.orders}
              />
              {allOrders?.orders && length > 0 ? (
                <Col span={24}>
                  <Row justify='center' style={{ margin: 30 }}>
                    <Pagination
                      total={allOrders?.length}
                      onChange={handlePaginationMethod}
                      responsive={true}
                      size='large'
                      current={currentPage}
                      showSizeChanger={false}
                    />
                  </Row>
                </Col>
              ) : (
                ''
              )}
            </Spin>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Orders;
