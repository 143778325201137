import jwtDecode from 'jwt-decode';
import moment from 'moment';
import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';
import {
  add,
  isArray,
  isEmpty,
  isObject,
  join,
  multiply,
  subtract,
  sum,
  toNumber,
} from 'lodash';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';

export const phoneFormatterAccordingToCountryCode = (
  countryFormat,
  phoneNumberArray
) => {
  var pattern = /[.](?!\d*$.)/g;
  var replacement = ' ';
  return countryFormat.replace(pattern, function (match) {
    replacement = phoneNumberArray.shift();
    if (typeof replacement == 'number' || typeof replacement == 'string') {
      return replacement;
    }
    return '';
  });
};
const text = '(...) ... ....';
const blankData = ['7', '8', '1', '9', '9', '1', '2', '5', '1', '9'];
// output: And also an array like this

// export const customToken = () => {
//   if (typeof window !== "undefined") {
//     return localStorage?.getItem("access_token")
//       ? localStorage?.getItem("access_token")
//       : null;
//     // : getCookie("access_token");
//   }
// };

export const getepochTime = () => {
  const currentDate = new Date();
  const epochTime = Math.floor(currentDate.getTime() / 1000);
  return epochTime;
};

export const CheckTokenExpired = () => {
  const token = localStorage.getItem('access_token');
  const decodedToken = token ? jwtDecode(token) : null;
  const newDate = new Date(decodedToken.exp * 1000);
  const formattedNewDate = moment(newDate).format('YYYY-MM-DD');
  const currentDate = moment().format('YYYY-MM-DD');
  const differenceDate = moment(currentDate).diff(formattedNewDate, 'days');
  const isTokenExpired = differenceDate > 0;

  return isTokenExpired;
};

export const CountryViseFormat = (CountryNumber) => {
  const phoneNumber = parsePhoneNumber(CountryNumber);
  if (isValidPhoneNumber(CountryNumber)) {
    return phoneNumber.formatInternational();
  } else if (phoneNumber.isValid()) {
    return phoneNumber.formatInternational();
  } else {
    return CountryNumber;
  }
};
// export const falseyValueCases = (data) => {
//   if (isObject(data)) {
//     // Fall-through switch cases. -> case return true, depending on case value (boolean).
//     switch (true) {
//       case isEmpty(data):
//         return true;
//       default:
//         return false;
//     }
//   } else {
//     switch (data) {
//       case null:
//         return true;
//       case typeof data === "function":
//         return true;
//       case "null":
//         return true;
//       case "":
//         return true;
//       case undefined:
//         return true;
//       case "undefined":
//         return true;
//       case {}:
//         return true;
//       case []:
//         return true;
//       case NaN:
//         return true;
//       case 0:
//         return true;
//       case "0":
//         return true;
//       case "NaN":
//         return true;
//       case false:
//         return false;
//       case true:
//         return true;
//       default:
//         return false;
//     }
//   }
// };
export const falseyValueCases = (data) => {
  switch (data) {
    case null:
      return true;
    case 'null':
      return true;
    case '':
      return true;
    case undefined:
      return true;
    case 'undefined':
      return true;
    case NaN:
      return true;
    case 'NaN':
      return true;
    case 0:
      return true;
    case '0':
      return true;
    case []:
      return true;
    default:
      return false;
  }
};

export const ImageEnv = (image) => {
  const image_url =
    process.env.REACT_APP_ENV === 'production'
      ? `${process.env.REACT_APP_PROD_IMAGE_URL}/${image}`
      : `${process.env.REACT_APP_DEV_IMAGE_URL}/${image}`;
  return image_url;
};

export const getTablePositions = (data, type) => {
  return data?.map((table) => {
    return toNumber(type === 'y' ? table?.y_position : table?.x_position);
  });
};

export const useDimensions = () => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const DimensionHandler = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };
  useEffect(() => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
    window.addEventListener('resize', DimensionHandler);
    return () => window.removeEventListener('resize', DimensionHandler);
  }, [width, height]);
  return { width, height };
};

export const getItemsCustomizations = (data, item, callback, type) => {
  const customizationData = data
    ?.filter((filterCustomizationItem) => {
      return item?.id === filterCustomizationItem?.parent_id;
    })
    ?.map((customizationItem) => {
      return type === 'default'
        ? customizationItem
        : callback
        ? callback(customizationItem)
        : customizationItem?.item_quantity +
          ' x ' +
          customizationItem?.item?.name;
    });

  return type === 'default' ? customizationData : join(customizationData, ', ');
};

export const getItemPriceWithTaxations = (data, type, organizationDetails) => {
  const tax_rate_detail = sum(
    organizationDetails?.taxations?.map((taxation) => {
      return taxation?.tax_rate / 100;
    })
  );

  const item_price = toNumber(
    type === 'order-customization' || type === 'order-item'
      ? data?.display_item_price
      : type !== 'customization'
      ? data?.item?.display_price
      : data?.display_price
  );

  return (type !== 'customization' ? data?.item?.is_gst : data?.is_gst)
    ? organizationDetails?.inclusive_gst
      ? subtract(item_price, multiply(item_price, tax_rate_detail) / 100)
      : add(item_price, multiply(item_price, tax_rate_detail) / 100)
    : item_price;
};

export const getTotalByTaxation = (data, taxation, orgDetails, type) => {
  const taxRate = taxation?.display_tax_rate
    ? toNumber(taxation?.display_tax_rate)
    : taxation?.tax_rate / 100;

  const getCalculatedTaxByItem = (item) => {
    const itemTotal =
      type === 'order'
        ? toNumber(item?.display_item_price)
        : multiply(
            toNumber(item?.item?.display_price),
            item?.item?.item_quantity
          );

    const itemCustomizations = item?.customized_items;

    return sum([
      item?.item?.is_gst ? multiply(itemTotal, taxRate) / 100 : 0,
      itemCustomizations?.length > 0
        ? sum(
            itemCustomizations?.map((customization_item) => {
              const totalCustomizationTotal =
                type === 'order'
                  ? toNumber(customization_item?.display_item_price)
                  : multiply(
                      toNumber(customization_item?.display_price),
                      item?.item?.item_quantity
                    );

              return (
                type === 'order'
                  ? customization_item?.item?.is_gst
                  : customization_item?.is_gst
              )
                ? add(multiply(totalCustomizationTotal, taxRate) / 100)
                : 0;
            })
          )
        : 0,
    ]);
  };

  const calculatedTaxRate = isArray(data)
    ? sum(
        data?.map((item) => {
          return getCalculatedTaxByItem(item);
        })
      )
    : getCalculatedTaxByItem(data);

  return calculatedTaxRate;
};

export const getCurrentDayAndDate = (type) => {
  const currentDate = moment().format(
    type === 'date' ? 'DD-MM-YYYY' : type === 'day' ? 'dddd' : null
  );
  return currentDate;
};

export const convertEpochToDate = (epochTime, format) => {
  const date = moment.unix(epochTime);
  return date.format(format);
};
export const convertTimeToOrganizationTimeZone = (time, timeZone, format) => {
  return dayjs(time).tz(timeZone).format(format);
};
export const dateComparison = (
  firstDate,
  secondDate,
  comparisonType,
  period
) => {
  //period - day,date,month,year
  if (comparisonType === 'same') {
    return moment(firstDate).isSame(secondDate, period);
  } else if (comparisonType === 'difference') {
    return moment(firstDate, 'DD-MM-YYYY').diff(
      moment(secondDate, 'DD-MM-YYYY'),
      period
    );
  }
};
